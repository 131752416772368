export const FETCH_TEAM = "FETCH_TEAM";
export const FETCH_TEAM_PENDING = "FETCH_TEAM_PENDING";
export const FETCH_TEAM_REJECTED = "FETCH_TEAM_REJECTED";
export const FETCH_TEAM_FULFILLED = "FETCH_TEAM_FULFILLED";

export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const SET_SELECTED_INDEX = "SET_SELECTED_INDEX";
export const SET_SELECTED_VIEW = "SET_SELECTED_VIEW";
export const SET_SELECTED_TEAM = "SET_SELECTED_TEAM";

export const TOGGLE_SELECTED_PLAYER = "TOGGLE_SELECTED_PLAYER";
export const RESET_SELECTED_PLAYERS = "RESET_SELECTED_PLAYERS";
export const TOGGLE_PLAYER_LIST = "TOGGLE_PLAYER_LIST";
export const TOGGLE_TURBO_DRIVER_POPUP = "TOGGLE_TURBO_DRIVER_POPUP";
export const SET_TURBO_DRIVER = "SET_TURBO_DRIVER";
export const SET_TEMP_TURBO_DRIVER = "SET_TEMP_TURBO_DRIVER";
export const TOGGLE_TEAM_NAME_POPUP = "TOGGLE_TEAM_NAME_POPUP";
export const SET_TEAM_NAME = "SET_TEAM_NAME";
export const TOGGLE_PROFILE_POPUP = "TOGGLE_PROFILE_POPUP";
export const SET_PROFILE_DATA = "SET_PROFILE_DATA";
export const TOGGLE_TRANSFER_POPUP = "TOGGLE_TRANSFER_POPUP";
export const TOGGLE_CHIPS_POPUP = "TOGGLE_CHIPS_POPUP";
export const TOGGLE_FILTER_POPUP = "TOGGLE_FILTER_POPUP";
export const TOGGLE_ALERT_POPUP = "TOGGLE_ALERT_POPUP";
export const TOGGLE_CIRCUIT_POPUP = "TOGGLE_CIRCUIT_POPUP";
export const TOGGLE_CONFIRM_CHIPS_POPUP = "TOGGLE_CONFIRM_CHIPS_POPUP";
export const TOGGLE_PLAYER_POPUP = "TOGGLE_PLAYER_POPUP";
export const TOGGLE_EXTRA_TURBO_DRIVER_POPUP =
  "TOGGLE_EXTRA_TURBO_DRIVER_POPUP";
export const SET_EXTRA_TURBO_DRIVER = "SET_EXTRA_TURBO_DRIVER";
export const SET_TEMP_EXTRA_TURBO_DRIVER = "SET_TEMP_EXTRA_TURBO_DRIVER";
export const TOGGLE_RACE_WEEK_TAB = "TOGGLE_RACE_WEEK_TAB";

export const SET_BUDGET = "SET_BUDGET";

export const SAVE_TEAM = "SAVE_TEAM";
export const SAVE_TEAM_PENDING = "SAVE_TEAM_PENDING";
export const SAVE_TEAM_REJECTED = "SAVE_TEAM_REJECTED";
export const SAVE_TEAM_FULFILLED = "SAVE_TEAM_FULFILLED";
export const SAVE_TEAM_RESET = "SAVE_TEAM_RESET";

export const TRANSFER_TEAM = "TRANSFER_TEAM";
export const TRANSFER_TEAM_PENDING = "TRANSFER_TEAM_PENDING";
export const TRANSFER_TEAM_REJECTED = "TRANSFER_TEAM_REJECTED";
export const TRANSFER_TEAM_FULFILLED = "TRANSFER_TEAM_FULFILLED";
export const TRANSFER_TEAM_RESET = "TRANSFER_TEAM_RESET";

export const UPDATE_CAPTAIN = "UPDATE_CAPTAIN";
export const UPDATE_CAPTAIN_PENDING = "UPDATE_CAPTAIN_PENDING";
export const UPDATE_CAPTAIN_REJECTED = "UPDATE_CAPTAIN_REJECTED";
export const UPDATE_CAPTAIN_FULFILLED = "UPDATE_CAPTAIN_FULFILLED";
export const RESET_UPDATE_CAPTAIN = "RESET_UPDATE_CAPTAIN";

export const FETCH_BOOSTERS = "FETCH_BOOSTERS";
export const FETCH_BOOSTERS_PENDING = "FETCH_BOOSTERS_PENDING";
export const FETCH_BOOSTERS_REJECTED = "FETCH_BOOSTERS_REJECTED";
export const FETCH_BOOSTERS_FULFILLED = "FETCH_BOOSTERS_FULFILLED";
export const SET_SELECTED_BOOSTER = "SET_SELECTED_BOOSTER";
export const SET_TEMP_BOOSTER = "SET_TEMP_BOOSTER";
export const SET_REPLACED_PLAYER = "SET_REPLACED_PLAYER";

export const SET_FILTERS = "SET_FILTERS";
export const RESET_FILTERS = "RESET_FILTERS";
export const RESET_GO_BACK = "RESET_GO_BACK";
export const APPLY_FILTERS = "APPLY_FILTERS";

export const UPDATE_TEAM_NAME = "UPDATE_TEAM_NAME";
export const UPDATE_TEAM_NAME_PENDING = "UPDATE_TEAM_NAME_PENDING";
export const UPDATE_TEAM_NAME_REJECTED = "UPDATE_TEAM_NAME_REJECTED";
export const UPDATE_TEAM_NAME_FULFILLED = "UPDATE_TEAM_NAME_FULFILLED";
export const RESET_UPDATE_TEAM_NAME = "RESET_UPDATE_TEAM_NAME";

export const GET_USER_GAME_DAYS = "GET_USER_GAME_DAYS";
export const GET_USER_GAME_DAYS_PENDING = "GET_USER_GAME_DAYS_PENDING";
export const GET_USER_GAME_DAYS_REJECTED = "GET_USER_GAME_DAYS_REJECTED";
export const GET_USER_GAME_DAYS_FULFILLED = "GET_USER_GAME_DAYS_FULFILLED";

export const GET_USER_GAME_DAYS_V1 = "GET_USER_GAME_DAYS_V1";
export const GET_USER_GAME_DAYS_V1_PENDING = "GET_USER_GAME_DAYS_V1_PENDING";
export const GET_USER_GAME_DAYS_V1_REJECTED = "GET_USER_GAME_DAYS_V1_REJECTED";
export const GET_USER_GAME_DAYS_V1_FULFILLED =
  "GET_USER_GAME_DAYS_V1_FULFILLED";

export const FETCH_DETAILED_TEAM = "FETCH_DETAILED_TEAM";
export const FETCH_DETAILED_TEAM_PENDING = "FETCH_DETAILED_TEAM_PENDING";
export const FETCH_DETAILED_TEAM_REJECTED = "FETCH_DETAILED_TEAM_REJECTED";
export const FETCH_DETAILED_TEAM_FULFILLED = "FETCH_DETAILED_TEAM_FULFILLED";

export const FETCH_OPP_DETAILED_TEAM = "FETCH_OPP_DETAILED_TEAM";
export const FETCH_OPP_DETAILED_TEAM_PENDING =
  "FETCH_OPP_DETAILED_TEAM_PENDING";
export const FETCH_OPP_DETAILED_TEAM_REJECTED =
  "FETCH_OPP_DETAILED_TEAM_REJECTED";
export const FETCH_OPP_DETAILED_TEAM_FULFILLED =
  "FETCH_OPP_DETAILED_TEAM_FULFILLED";
export const DISCARD_TRANSFERS = "DISCARD_TRANSFERS";

export const FETCH_OPP_USER_GAME_DAYS = "FETCH_OPP_USER_GAME_DAYS";
export const FETCH_OPP_USER_GAME_DAYS_PENDING =
  "FETCH_OPP_USER_GAME_DAYS_PENDING";
export const FETCH_OPP_USER_GAME_DAYS_REJECTED =
  "FETCH_OPP_USER_GAME_DAYS_REJECTED";
export const FETCH_OPP_USER_GAME_DAYS_FULFILLED =
  "FETCH_OPP_USER_GAME_DAYS_FULFILLED";
export const RESET_OPP_TEAM = "RESET_OPP_TEAM";

export const FETCH_H2H_OPPONENT_TEAM = "FETCH_H2H_OPPONENT_TEAM";
export const FETCH_H2H_OPPONENT_TEAM_PENDING =
  "FETCH_H2H_OPPONENT_TEAM_PENDING";
export const FETCH_H2H_OPPONENT_TEAM_REJECTED =
  "FETCH_H2H_OPPONENT_TEAM_REJECTED";
export const FETCH_H2H_OPPONENT_TEAM_FULFILLED =
  "FETCH_H2H_OPPONENT_TEAM_FULFILLED";

export const FETCH_H2H_OPPONENT_USER_GAME_DAYS =
  "FETCH_H2H_OPPONENT_USER_GAME_DAYS";
export const FETCH_H2H_OPPONENT_USER_GAME_DAYS_PENDING =
  "FETCH_H2H_OPPONENT_USER_GAME_DAYS_PENDING";
export const FETCH_H2H_OPPONENT_USER_GAME_DAYS_REJECTED =
  "FETCH_H2H_OPPONENT_USER_GAME_DAYS_REJECTED";
export const FETCH_H2H_OPPONENT_USER_GAME_DAYS_FULFILLED =
  "FETCH_H2H_OPPONENT_USER_GAME_DAYS_FULFILLED";
export const RESET_H2H_OPPONENT = "RESET_H2H_OPPONENT";

export const RESET_GAMEPLAY_PERSISTER = "RESET_GAMEPLAY_PERSISTER";
