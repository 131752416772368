import React from "react";
import { getTranslations } from "../../../../Common/utils";

const TotalPoints = ({ totalPts, isNoNegative }) => {
  return (
    <>
      <div className="si-totalPts__box">
        <div className="si-totalPts__title">
          <span>{getTranslations("driver_stats_modal_total", "Total")}:</span>
        </div>
        <div
          className={`si-totalPts__counts ${
            parseInt(totalPts) < 0 && "si-negative"
          } ${isNoNegative && parseInt(totalPts) < 0 ? "si-strikeOut" : ""}`}
        >
          <span>
            <em>{!isNaN(totalPts) ? totalPts : 0}</em> pts
          </span>
        </div>
      </div>
    </>
  );
};

export default TotalPoints;
