import React, { useEffect, useState } from "react";
import { MatchRecord } from "../../MatchRecord";
import { getPath, getTranslations } from "../../../Common/utils";
import {
  getH2HUserRaceWeekWiseSummary,
  h2hCurrentUser,
  h2hOpponentUser,
} from "../../../Common/utils/league";
import { useDispatch, useSelector } from "react-redux";
import { leaderboard, league } from "../../../redux/actions";
import { OPT_TYPE } from "../../../Common/constants";
import { MatchHeader } from "../../MatchHeader";
import { HeadToHeadPoints } from "../../HeadToHead";
import { getBooster } from "../../../Common/utils/booster";
import { ErrorMessage } from "../../Error/message";
import { Accordion } from "../../Accordion";
import AccordionChild from "../../Accordion/AccordionChild";
import { TableHeader } from "../../TableHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { getMatchStatus } from "../../../Common/utils/fixture";

const H2HSeasonSoFar = ({ optType, gameDayId, phaseId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const gameplayState = useSelector((state) => state.gameplay);
  const fixtureState = useSelector((state) => state.fixture);
  const leagueState = useSelector((state) => state.league);
  const leaderBoardState = useSelector((state) => state.leaderboard);
  const userState = useSelector((state) => state.user);
  const translationState = useSelector((state) => state.translation);

  useEffect(() => {
    const payload = {
      guid: userState?.data?.GUID,
      optType: OPT_TYPE,
      leagueId: leagueState?.selectedLeague?.leagueId,
    };

    dispatch(league.getH2HRaceWeekWiseResult(payload));
  }, [dispatch, leagueState?.selectedLeague?.leagueId, userState?.data?.GUID]);

  const [rankActiveIndex, setRankActiveIndex] = useState(0);
  const updateRankActiveIndex = (index, value) => {
    if (!value) {
      setRankActiveIndex(-1);
    } else {
      setRankActiveIndex(index);
    }
  };

  useEffect(() => {
    const getH2HLeaderBoard = () => {
      const payload = {
        guid: userState?.data?.GUID,
        optType: optType,
        leagueId: leagueState?.selectedLeague?.leagueId,
        gameDayId: gameDayId,
        phaseId: phaseId,
        pageNo: 1,
        topNo: 10,
      };
      dispatch(leaderboard.getH2HLeaderBoard(payload));
    };

    getH2HLeaderBoard();
  }, [
    dispatch,
    leagueState?.selectedLeague?.leagueId,
    userState?.data?.GUID,
    optType,
    gameDayId,
    phaseId,
  ]);

  const selectedRaces =
    gameDayId === 0
      ? leagueState?.h2hRaceWeekWiseResultData
      : leagueState?.h2hRaceWeekWiseResultData?.filter(
          (race) => race?.GamedayId == gameDayId
        );

  return (
    <div className="si-battle__grid">
      {!!leaderBoardState?.leaderBoard?.length && (
        <div className="si-main__row">
          <div className="si-league__member-box si-league__member-box--self">
            <Accordion
              activeIndex={rankActiveIndex}
              accordionChild={
                <AccordionChild
                  isActive={rankActiveIndex === 0}
                  updateActiveIndex={updateRankActiveIndex}
                  index={0}
                  accordionConfig={{ showAccordRHS: false }}
                  headComponentLHS={
                    <>
                      <h2 className="si-accordion__header">
                        <span>
                          {getTranslations(
                            "league_hth_leaderboard_table_title",
                            "Ranks"
                          )}
                        </span>
                      </h2>
                    </>
                  }
                  bodyComponent={
                    <>
                      <div className="si-league__member-tbl">
                        <TableHeader
                          tableConfig={[
                            {
                              head: getTranslations(
                                "league_hth_leaderboard_table_column_1",
                                "Rank"
                              ),
                              cls: "rank",
                              key: "rank",
                            },
                            {
                              head: getTranslations(
                                "league_hth_leaderboard_table_column_2",
                                "Name"
                              ),
                              cls: "name",
                              key: "teamName",
                            },
                            {
                              head: getTranslations(
                                "league_hth_leaderboard_table_column_3",
                                "Battle Points"
                              ),
                              cls: "pts",
                              key: "points",
                            },
                          ]}
                          tableData={leaderBoardState?.leaderBoard}
                          isHandleClickDisabled={true}
                        />
                      </div>
                    </>
                  }
                />
              }
            />
          </div>
        </div>
      )}
      <MatchRecord
        customCls={"si-matchRecord__wrap--hero"}
        heading={getTranslations("league_hth_season_so_far_summary", "Summary")}
        dataMatchRecord={getH2HUserRaceWeekWiseSummary(leagueState)}
      />
      {selectedRaces?.map((race) => (
        <div className="si-battle__head">
          <div className="si-battle__matchHeader">
            <MatchHeader
              MatchHeaderConfig={{
                MatchHeaderRHS: false,
                MatchHeaderLHS: false,
                MatchFixtureInfo: false,
                MatchHeaderExtra: true,
              }}
              fixture={fixtureState?.list?.find(
                (fixture) => fixture?.GamedayId === race?.GamedayId
              )}
              tagName="h2"
            />
            <MatchHeader
              MatchHeaderConfig={{
                MatchHeaderRHS: false,
                MatchFixtureInfo: false,
              }}
              fixture={fixtureState?.list?.find(
                (fixture) => fixture?.GamedayId === race?.GamedayId
              )}
              tagName="h2"
            />
          </div>
          <HeadToHeadPoints
            user={h2hCurrentUser(leagueState?.h2hSingleRaceResultData)}
            opponent={h2hOpponentUser(leagueState?.h2hSingleRaceResultData)}
            userBooster={getBooster(
              gameplayState?.boosters?.Value,
              h2hCurrentUser(race?.raceWeekDetailsHth)?.boosterId
            )}
            opponentBooster={getBooster(
              gameplayState?.boosters?.Value,
              h2hOpponentUser(race?.raceWeekDetailsHth)?.boosterId
            )}
            userPoints={h2hCurrentUser(race?.raceWeekDetailsHth)?.currentPoint}
            opponentPoints={
              h2hOpponentUser(race?.raceWeekDetailsHth)?.currentPoint
            }
            isUserWin={
              h2hCurrentUser(race?.raceWeekDetailsHth)?.battleResult === "W"
            }
            isOpponentWin={
              h2hOpponentUser(race?.raceWeekDetailsHth)?.battleResult === "W"
            }
            handleUserClick={() => {
              navigate(
                getPath(translationState, "detailedOppTeam", {
                  gameDayId: race?.GamedayId,
                  phaseId: 1,
                  teamNo: h2hCurrentUser(race?.raceWeekDetailsHth)?.teamNo,
                }),
                {
                  state: {
                    guid: h2hCurrentUser(race?.raceWeekDetailsHth)?.userguid,
                    fromRoute: location?.pathname + "?st=2",
                    raceWeeks: leagueState?.selectedLeague?.raceweek,
                  },
                }
              );
            }}
            handleOpponentClick={() => {
              navigate(
                getPath(translationState, "detailedOppTeam", {
                  gameDayId: race?.GamedayId,
                  phaseId: 1,
                  teamNo: h2hOpponentUser(race?.raceWeekDetailsHth)?.teamNo,
                }),
                {
                  state: {
                    guid: h2hOpponentUser(race?.raceWeekDetailsHth)?.userguid,
                    fromRoute: location?.pathname + "?st=2",
                    raceWeeks: leagueState?.selectedLeague?.raceweek,
                  },
                }
              );
            }}
          />
          {h2hCurrentUser(race?.raceWeekDetailsHth)?.battleResult === "D" && (
            <ErrorMessage
              customCls={"si-errMsg--default"}
              text={getTranslations("league_hth_season_so_far_draw", "Draw")}
              iconClassName={false}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default H2HSeasonSoFar;
