import { getCookie, getTranslations } from "./utils";

export const ALLOWED_LANGUAGES = ["en", "de", "fr", "es", "it", "pt-br", "nl"];
export const JSON_VERSION = process.env.REACT_APP_JSON_VERSION;

export const IMAGE_BASE_URL = process.env.REACT_APP_IMAGE_BASE_NAME;
export const CLOUDINARY_IMAGE_BASE_URL =
  process.env.REACT_APP_CLOUDINARY_BASE_NAME;

export const DESKTOP = "Desktop";
export const TABLET = "Tablet";
export const MOBILE = "Mobile";

export const PROFANE_AND_PROVISIONAL = "PROFANE_AND_PROVISIONAL";
export const UPDATED_BY_SYSTEM = "UPDATED_BY_SYSTEM ";

export const MOBILE_SM_MIN_WIDTH = "(min-width: 0px)";
export const MOBILE_SM_MAX_WIDTH = "(min-width: 320px)";
export const MOBILE_MIN_WIDTH = "(min-width: 321px)";
export const MOBILE_MAX_WIDTH = "(max-width: 576px)";
export const TABLET_MIN_WIDTH = "(min-width: 577px)";
export const TABLET_MAX_WIDTH = "(max-width: 768px)";
export const LAPTOP_SM_MIN_WIDTH = "(min-width: 769px)";
export const LAPTOP_SM_MAX_WIDTH = "(min-width: 1024px)";
export const LAPTOP_MD_MIN_WIDTH = "(min-width: 1025px)";
export const LAPTOP_MD_MAX_WIDTH = "(min-width: 1160px)";
export const LAPTOP_MIN_WIDTH = "(min-width: 1161px)";
export const LAPTOP_MAX_WIDTH = "(max-width: 1319px)";
export const DESKTOP_MIN_WIDTH = "(min-width: 1320px)";

export const FANTASY_ANDROID = "Fantasy Android";
export const FANTASY_IOS = "Fantasy iOS";
export const FANTASY_WEBSITE = "Fantasy Website";

export const PERSONAL = "PERSONAL";
export const SEASON = "SEASON";

export const EOT_USER_API_1 = "services/user/cards/{{socialId}}/eos";
export const EOT_USER_API_2 = "feeds/eos/user/{{socialId}}.json";

export const SUBSCRIPTION_PACKAGE_TYPE = "SUBSCRIPTION_PACKAGE";
export const ADDITIONAL_SUBSCRIPTIONS_TYPE = "ADDITIONAL_SUBSCRIPTIONS";
export const F1_TV_PRO_ANNUAL = "F1 TV Pro Annual";
export const F1_TV_PRO_MONTHLY = "F1 TV Pro Monthly";
export const F1_TV_ACCESS_ANNUAL = "F1 TV Access Annual";
export const F1_TV_ACCESS_MONTHLY = "F1 TV Access Monthly";
export const PRO_ANNUAL = "Pro Annual";
export const PRO_MONTHLY = "Pro Monthly";
export const ACCESS_ANNUAL = "Access Annual";
export const ACCESS_MONTHLY = "Access Monthly";

export const WEB = "Web";
export const WEBVIEW = "WebView";

export const INTERNAL = "internal";

export const CREATE_LEAGUE = "create-league";
export const JOIN_LEAGUE = "join-league";

export const LANDSCAPE = "Landscape";
export const PORTRAIT = "Portrait";

export const STANDINGS = "Standings";
export const GAMING = "Gaming";
export const MORE = "More";
export const RESULTS = "Results";

export const REGISTER = "register";
export const LOGIN = "login";
export const SIGN_IN = "sign in";
export const MY_ACCOUNT = "my-account";
export const SUBSCRIBE = "Subscribe";

export const WEEKEND = "Weekend";
export const QUALIFYING = "Qualifying";
export const RACE = "Race";
export const SPRINT = "Sprint";

export const DRIVER = "DRIVER";
export const CONSTRUCTOR = "CONSTRUCTOR";

export const TOTAL = "Total";

export const MAX_DRIVERS = 5;
export const MAX_CONSTRUCTORS = 2;
export const MAX_TEAM_NAME_CHARACTERS = 25;
export const MIN_TEAM_NAME_CHARACTERS = 6;
export const MAX_MENU_ITEMS = 3;

export const ONE_SECOND = 1000;
export const ONE_MINUTE = ONE_SECOND * 60;
export const ONE_HOUR = ONE_MINUTE * 60;
export const ONE_DAY = ONE_HOUR * 24;

export const MIX_API_INTERVAL = 15000;
export const MIX_API_KEY = "si-mix-api-buster";

export const IMAGE_VERSION =
  typeof getCookie === "function" && getCookie(MIX_API_KEY)
    ? getCookie(MIX_API_KEY)
    : process.env.REACT_APP_IMAGE_VERSION;

export const ANNOUNCEMENT_INFO_POPUP_KEY = (language) =>
  `si-${language}-info-popup`;

export const BUILD_VERSION = "si-bv";
export const BUILD_REFRESH = "si-bv-refresh";

export const OPT_TYPE = 1;
export const PLATFORM_ID = 1;
export const PLATFORM_VERSION = "1";
export const PLATFORM_CATEGORY = "web";
export const CLIENT_ID = 1;

export const MAX_BUDGET = 100;
export const EXTRA_TRANSFER_COST = 10;
export const TRENDS_MIN_RACE_WEEK = 2;

export const MAX_LEAGUE_MEMBERS = 99999999;
export const MIN_LEAGUE_CODE_LENGTH = 11;
export const MAX_LEAGUE_NAME_CHARACTERS = 50;
export const MIN_LEAGUE_NAME_CHARACTERS = 3;

export const UPCOMING_TIMER_OFFSET = 40;

export const RACE_WEEKEND =
  typeof getTranslations === "function" &&
  getTranslations("my_team_race_weekend", "Race Weekend");
export const COMPLETED =
  typeof getTranslations === "function" &&
  getTranslations("my_team_completed_races", "Completed");

export const TYPE_F1_LOGIN_OFFLINE_MODE = "TYPE_F1_LOGIN_OFFLINE_MODE";

export const RE_RENDER_LOCAL_STORAGE_KEY = "si-tab-re-render";

export const cf_user_team_create = {
  EXP_INVALID_USER_DB_INSTANCE: 1051,
  EXP_GAMEDY_LOCKED: 1052,
  EXP_USER_NOT_FOUND: 1053,
  EXP_TEAM_ALREADY_CREATED: 1054,
  EXP_IVALID_GAMEDAY_PLAYER_COUNT: 1055,
  EXP_IVALID_TEAM_COUNT: 1056,
  EXP_DUPLICATE_PLAYERID_FOUND: 1057,
  EXP_INVALID_TEAM_NO: 1058,
  EXP_TEAM_VALUE_EXCEEDED: 1059,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1060,
  EXP_TEAM_NAME_EXISTS: 1061,
  EXP_TEAM_NAME_LENGTH_NOT_MATCH: 1062,
  PROFANITY_CHECK: -71,
  EXP_ACHIVEMENT_TEAM1: 1063,
  EXP_ACHIVEMENT_TEAM3: 1064,
};

export const cf_user_team_create_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_create.EXP_INVALID_USER_DB_INSTANCE]: "Something went wrong",
  [cf_user_team_create.EXP_GAMEDY_LOCKED]: "Game day locked",
  [cf_user_team_create.EXP_USER_NOT_FOUND]: "User not found",
  [cf_user_team_create.EXP_TEAM_ALREADY_CREATED]: "Team already created",
  [cf_user_team_create.EXP_IVALID_GAMEDAY_PLAYER_COUNT]:
    "Invalid Game day Player Count",
  [cf_user_team_create.EXP_IVALID_TEAM_COUNT]: "Invalid Team Count",
  [cf_user_team_create.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Duplicate driver or constructor found",
  [cf_user_team_create.EXP_INVALID_TEAM_NO]: "Invalid Team Number",
  [cf_user_team_create.EXP_TEAM_VALUE_EXCEEDED]: "Team Value Exceeded",
  [cf_user_team_create.EXP_CAPTAIN_DOES_NOT_EXISTS]: "DRS Boost not selected",
  [cf_user_team_create.EXP_TEAM_NAME_EXISTS]: "Team name already taken",
  [cf_user_team_create.EXP_TEAM_NAME_LENGTH_NOT_MATCH]:
    "Invalid Team name length",
  [cf_user_team_create.PROFANITY_CHECK]: "Team name not allowed",
  [cf_user_team_create.EXP_ACHIVEMENT_TEAM1]: "Achievement already unlocked",
  [cf_user_team_create.EXP_ACHIVEMENT_TEAM3]: "Achievement already unlocked",
};

export const cf_user_team_transfer = {
  EXP_INVALID_USER_DB_INSTANCE: 1101,
  EXP_GAMEDY_LOCKED: 1102,
  EXP_INVALID_ACTION: 1103,
  EXP_INVALID_GAMEDAY_PLAYER_COUNT: 1104,
  EXP_WILDCARD_BOOSTER_NOT_VALID: 1105,
  EXP_IVALID_TEAM_COUNT: 1106,
  EXP_DUPLICATE_PLAYERID_FOUND: 1107,
  EXP_INVALID_TEAM_NO: 1108,
  EXP_TEAM_VALUE_EXCEEDED: 1109,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1110,
  EXP_TEAM_NAME_EXISTS: 1111,
  EXP_INVALID_TRANSFER_DML: 1112,
  EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE: 1113,
  EXP_FREE_TRANSFER_GAMEDAY: 1114,
  EXP_GAMEDAY_NOT_CURRENT: 1115,
  EXP_TEAM_NOT_EXISTS: 1116,
  EXP_SOME_ERROR_OCCURED_3: 1117,
  EXP_ACHIVEMENT_ALREADY_UNLOCK: 1118,
  EXP_MG_CAPTAIN_DOES_NOT_EXISTS: 1119,
};

export const cf_user_team_transfer_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_create.EXP_INVALID_USER_DB_INSTANCE]: "Something went wrong",
  [cf_user_team_create.EXP_GAMEDY_LOCKED]: "Game day locked",
  [cf_user_team_create.EXP_INVALID_ACTION]: "Invalid Action",
  [cf_user_team_create.EXP_INVALID_GAMEDAY_PLAYER_COUNT]:
    "Invalid Game day Player Count",
  [cf_user_team_create.EXP_WILDCARD_BOOSTER_NOT_VALID]: "Wildcard not valid",
  [cf_user_team_create.EXP_IVALID_TEAM_COUNT]: "Invalid Team count",
  [cf_user_team_create.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Duplicate driver or constructor found",
  [cf_user_team_create.EXP_INVALID_TEAM_NO]: "Invalid Team Number",
  [cf_user_team_create.EXP_TEAM_VALUE_EXCEEDED]: "Team Value Exceeded",
  [cf_user_team_create.EXP_CAPTAIN_DOES_NOT_EXISTS]: "DRS Boost not selected",
  [cf_user_team_create.EXP_TEAM_NAME_EXISTS]: "Team name already taken",
  [cf_user_team_create.EXP_INVALID_TRANSFER_DML]: "No Transfers made",
  [cf_user_team_create.EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE]:
    "Something went wrong",
  [cf_user_team_create.EXP_FREE_TRANSFER_GAMEDAY]: "Free transfer Game day",
  [cf_user_team_create.EXP_GAMEDAY_NOT_CURRENT]: "Invalid Game day",
  [cf_user_team_create.EXP_TEAM_NOT_EXISTS]: "Team not found",
  [cf_user_team_create.EXP_SOME_ERROR_OCCURED_3]: "Something went wrong",
  [cf_user_team_create.EXP_ACHIVEMENT_ALREADY_UNLOCK]:
    "Achievement already unlocked",
  [cf_user_team_create.EXP_MG_CAPTAIN_DOES_NOT_EXISTS]:
    "Extra DRS boost not selected",
};

export const cf_user_team_captain_upd = {
  EXP_INVALID_USER_DB_INSTANCE: 1151,
  EXP_GAMEDY_LOCKED: 1152,
  EXP_INVALID_ACTION: 1153,
  EXP_INVALID_GAMEDAY_PLAYER_COUNT: 1154,
  EXP_WILDCARD_BOOSTER_NOT_VALID: 1155,
  EXP_IVALID_TEAM_COUNT: 1156,
  EXP_DUPLICATE_PLAYERID_FOUND: 1157,
  EXP_INVALID_TEAM_NO: 1158,
  EXP_TEAM_VALUE_EXCEEDED: 1159,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1160,
  EXP_TEAM_NAME_EXISTS: 1161,
  EXP_INVALID_TRANSFER_DML: 1162,
  EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE: 1163,
  EXP_FREE_TRANSFER_GAMEDAY: 1164,
  EXP_GAMEDAY_NOT_CURRENT: 1165,
  EXP_TEAM_NOT_EXISTS: 1166,
  EXP_SOME_ERROR_OCCURED_3: 1167,
};

export const cf_user_team_captain_upd_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_captain_upd.EXP_INVALID_USER_DB_INSTANCE]:
    "Something went wrong",
  [cf_user_team_captain_upd.EXP_GAMEDY_LOCKED]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_INVALID_ACTION]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_INVALID_GAMEDAY_PLAYER_COUNT]:
    "Something went wrong",
  [cf_user_team_captain_upd.EXP_WILDCARD_BOOSTER_NOT_VALID]:
    "Something went wrong",
  [cf_user_team_captain_upd.EXP_IVALID_TEAM_COUNT]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Something went wrong",
  [cf_user_team_captain_upd.EXP_INVALID_TEAM_NO]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_TEAM_VALUE_EXCEEDED]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_CAPTAIN_DOES_NOT_EXISTS]:
    "Something went wrong",
  [cf_user_team_captain_upd.EXP_TEAM_NAME_EXISTS]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_INVALID_TRANSFER_DML]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE]:
    "Something went wrong",
  [cf_user_team_captain_upd.EXP_FREE_TRANSFER_GAMEDAY]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_GAMEDAY_NOT_CURRENT]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_TEAM_NOT_EXISTS]: "Something went wrong",
  [cf_user_team_captain_upd.EXP_SOME_ERROR_OCCURED_3]: "Something went wrong",
};

export const cf_user_team_limitless_upd = {
  EXP_INVALID_USER_DB_INSTANCE: 1201,
  EXP_GAMEDY_LOCKED: 1202,
  EXP_INVALID_ACTION: 1203,
  EXP_INVALID_GAMEDAY_PLAYER_COUNT: 1204,
  EXP_WILDCARD_BOOSTER_NOT_VALID: 1205,
  EXP_IVALID_TEAM_COUNT: 1206,
  EXP_DUPLICATE_PLAYERID_FOUND: 1207,
  EXP_INVALID_TEAM_NO: 1208,
  EXP_TEAM_VALUE_EXCEEDED: 1209,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1210,
  EXP_TEAM_NAME_EXISTS: 1211,
  EXP_INVALID_TRANSFER_DML: 1212,
  EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE: 1213,
  EXP_FREE_TRANSFER_GAMEDAY: 1214,
  EXP_GAMEDAY_NOT_CURRENT: 1215,
  EXP_TEAM_NOT_EXISTS: 1216,
  EXP_BOOSTER_ALREADY_TAKEN: 1217,
  EXP_ALL_BOOSTER_TAKEN: 1218,
  EXP_BOOSTER_NOT_ACTIVE: 1219,
  EXP_SOME_ERROR_OCCURED_3: 1220,
};

export const cf_user_team_limitless_upd_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_limitless_upd.EXP_INVALID_USER_DB_INSTANCE]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_GAMEDY_LOCKED]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_INVALID_ACTION]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_INVALID_GAMEDAY_PLAYER_COUNT]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_WILDCARD_BOOSTER_NOT_VALID]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_IVALID_TEAM_COUNT]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_INVALID_TEAM_NO]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_TEAM_VALUE_EXCEEDED]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_CAPTAIN_DOES_NOT_EXISTS]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_TEAM_NAME_EXISTS]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_INVALID_TRANSFER_DML]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_FREE_TRANSFER_GAMEDAY]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_GAMEDAY_NOT_CURRENT]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_TEAM_NOT_EXISTS]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_BOOSTER_ALREADY_TAKEN]:
    "Something went wrong",
  [cf_user_team_limitless_upd.EXP_ALL_BOOSTER_TAKEN]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_BOOSTER_NOT_ACTIVE]: "Something went wrong",
  [cf_user_team_limitless_upd.EXP_SOME_ERROR_OCCURED_3]: "Something went wrong",
};

export const cf_user_team_nonegative_upd = {
  EXP_INVALID_USER_DB_INSTANCE: 1251,
  EXP_GAMEDY_LOCKED: 1252,
  EXP_INVALID_ACTION: 1253,
  EXP_INVALID_GAMEDAY_PLAYER_COUNT: 1254,
  EXP_WILDCARD_BOOSTER_NOT_VALID: 1255,
  EXP_IVALID_TEAM_COUNT: 1256,
  EXP_DUPLICATE_PLAYERID_FOUND: 1257,
  EXP_INVALID_TEAM_NO: 1258,
  EXP_TEAM_VALUE_EXCEEDED: 1259,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1260,
  EXP_TEAM_NAME_EXISTS: 1261,
  EXP_INVALID_TRANSFER_DML: 1262,
  EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE: 1263,
  EXP_FREE_TRANSFER_GAMEDAY: 1264,
  EXP_GAMEDAY_NOT_CURRENT: 1265,
  EXP_TEAM_NOT_EXISTS: 1266,
  EXP_BOOSTER_ALREADY_TAKEN: 1267,
  EXP_ALL_BOOSTER_TAKEN: 1268,
  EXP_BOOSTER_NOT_ACTIVE: 1269,
  EXP_SOME_ERROR_OCCURED_3: 1270,
};

export const cf_user_team_nonegative_upd_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_INVALID_USER_DB_INSTANCE]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_GAMEDY_LOCKED]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_INVALID_ACTION]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_INVALID_GAMEDAY_PLAYER_COUNT]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_WILDCARD_BOOSTER_NOT_VALID]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_IVALID_TEAM_COUNT]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_INVALID_TEAM_NO]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_TEAM_VALUE_EXCEEDED]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_CAPTAIN_DOES_NOT_EXISTS]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_TEAM_NAME_EXISTS]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_INVALID_TRANSFER_DML]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_FREE_TRANSFER_GAMEDAY]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_GAMEDAY_NOT_CURRENT]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_TEAM_NOT_EXISTS]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_BOOSTER_ALREADY_TAKEN]:
    "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_ALL_BOOSTER_TAKEN]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_BOOSTER_NOT_ACTIVE]: "Something went wrong",
  [cf_user_team_nonegative_upd.EXP_SOME_ERROR_OCCURED_3]:
    "Something went wrong",
};

export const cf_user_team_drsenabled_upd = {
  EXP_INVALID_USER_DB_INSTANCE: 1301,
  EXP_GAMEDY_LOCKED: 1302,
  EXP_INVALID_ACTION: 1303,
  EXP_INVALID_GAMEDAY_PLAYER_COUNT: 1304,
  EXP_WILDCARD_BOOSTER_NOT_VALID: 1305,
  EXP_IVALID_TEAM_COUNT: 1306,
  EXP_DUPLICATE_PLAYERID_FOUND: 1307,
  EXP_INVALID_TEAM_NO: 1308,
  EXP_TEAM_VALUE_EXCEEDED: 1309,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1310,
  EXP_TEAM_NAME_EXISTS: 1311,
  EXP_INVALID_TRANSFER_DML: 1312,
  EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE: 1313,
  EXP_FREE_TRANSFER_GAMEDAY: 1314,
  EXP_GAMEDAY_NOT_CURRENT: 1315,
  EXP_TEAM_NOT_EXISTS: 1316,
  EXP_BOOSTER_ALREADY_TAKEN: 1317,
  EXP_ALL_BOOSTER_TAKEN: 1318,
  EXP_BOOSTER_NOT_ACTIVE: 1319,
  EXP_SOME_ERROR_OCCURED_3: 1320,
};

export const cf_user_team_drsenabled_upd_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_INVALID_USER_DB_INSTANCE]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_GAMEDY_LOCKED]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_INVALID_ACTION]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_INVALID_GAMEDAY_PLAYER_COUNT]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_WILDCARD_BOOSTER_NOT_VALID]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_IVALID_TEAM_COUNT]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_INVALID_TEAM_NO]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_TEAM_VALUE_EXCEEDED]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_CAPTAIN_DOES_NOT_EXISTS]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_TEAM_NAME_EXISTS]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_INVALID_TRANSFER_DML]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_FREE_TRANSFER_GAMEDAY]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_GAMEDAY_NOT_CURRENT]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_TEAM_NOT_EXISTS]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_BOOSTER_ALREADY_TAKEN]:
    "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_ALL_BOOSTER_TAKEN]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_BOOSTER_NOT_ACTIVE]: "Something went wrong",
  [cf_user_team_drsenabled_upd.EXP_SOME_ERROR_OCCURED_3]:
    "Something went wrong",
};

export const cf_user_team_megadriver_upd = {
  EXP_INVALID_USER_DB_INSTANCE: 1351,
  EXP_GAMEDY_LOCKED: 1352,
  EXP_INVALID_ACTION: 1353,
  EXP_INVALID_GAMEDAY_PLAYER_COUNT: 1354,
  EXP_WILDCARD_BOOSTER_NOT_VALID: 1355,
  EXP_IVALID_TEAM_COUNT: 1356,
  EXP_DUPLICATE_PLAYERID_FOUND: 1357,
  EXP_INVALID_TEAM_NO: 1358,
  EXP_TEAM_VALUE_EXCEEDED: 1359,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1360,
  EXP_TEAM_NAME_EXISTS: 1361,
  EXP_INVALID_TRANSFER_DML: 1362,
  EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE: 1363,
  EXP_FREE_TRANSFER_GAMEDAY: 1364,
  EXP_GAMEDAY_NOT_CURRENT: 1365,
  EXP_TEAM_NOT_EXISTS: 1366,
  EXP_BOOSTER_ALREADY_TAKEN: 1367,
  EXP_ALL_BOOSTER_TAKEN: 1368,
  EXP_BOOSTER_NOT_ACTIVE: 1369,
  EXP_SOME_ERROR_OCCURED_3: 1370,
};

export const cf_user_team_megadriver_upd_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_GAMEDY_LOCKED]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_INVALID_USER_DB_INSTANCE]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_INVALID_ACTION]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_INVALID_GAMEDAY_PLAYER_COUNT]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_WILDCARD_BOOSTER_NOT_VALID]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_IVALID_TEAM_COUNT]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_INVALID_TEAM_NO]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_TEAM_VALUE_EXCEEDED]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_CAPTAIN_DOES_NOT_EXISTS]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_TEAM_NAME_EXISTS]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_INVALID_TRANSFER_DML]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_FREE_TRANSFER_GAMEDAY]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_GAMEDAY_NOT_CURRENT]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_TEAM_NOT_EXISTS]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_BOOSTER_ALREADY_TAKEN]:
    "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_ALL_BOOSTER_TAKEN]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_BOOSTER_NOT_ACTIVE]: "Something went wrong",
  [cf_user_team_megadriver_upd.EXP_SOME_ERROR_OCCURED_3]:
    "Something went wrong",
};

export const cf_user_team_finalfix_upd = {
  EXP_INVALID_USER_DB_INSTANCE: 1401,
  EXP_GAMEDY_LOCKED: 1402,
  EXP_INVALID_ACTION: 1403,
  EXP_INVALID_GAMEDAY_PLAYER_COUNT: 1404,
  EXP_WILDCARD_BOOSTER_NOT_VALID: 1405,
  EXP_IVALID_TEAM_COUNT: 1406,
  EXP_DUPLICATE_PLAYERID_FOUND: 1407,
  EXP_INVALID_TEAM_NO: 1408,
  EXP_TEAM_VALUE_EXCEEDED: 1409,
  EXP_CAPTAIN_DOES_NOT_EXISTS: 1410,
  EXP_TEAM_NAME_EXISTS: 1411,
  EXP_INVALID_TRANSFER_DML: 1412,
  EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE: 1413,
  EXP_FREE_TRANSFER_GAMEDAY: 1414,
  EXP_GAMEDAY_NOT_CURRENT: 1415,
  EXP_TEAM_NOT_EXISTS: 1416,
  EXP_BOOSTER_ALREADY_TAKEN: 1417,
  EXP_ALL_BOOSTER_TAKEN: 1418,
  EXP_BOOSTER_NOT_ACTIVE: 1419,
  EXP_FINALFIX_LOCKED: 1420,
  EXP_SOME_ERROR_OCCURED_3: 1421,
};

export const cf_user_team_finalfix_upd_error_messages = {
  "-1": "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_INVALID_USER_DB_INSTANCE]:
    "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_GAMEDY_LOCKED]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_INVALID_ACTION]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_INVALID_GAMEDAY_PLAYER_COUNT]:
    "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_WILDCARD_BOOSTER_NOT_VALID]:
    "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_IVALID_TEAM_COUNT]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_DUPLICATE_PLAYERID_FOUND]:
    "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_INVALID_TEAM_NO]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_TEAM_VALUE_EXCEEDED]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_CAPTAIN_DOES_NOT_EXISTS]:
    "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_TEAM_NAME_EXISTS]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_INVALID_TRANSFER_DML]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_INVALID_IS_BOOSTER_1_TEAM_VALUE]:
    "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_FREE_TRANSFER_GAMEDAY]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_GAMEDAY_NOT_CURRENT]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_TEAM_NOT_EXISTS]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_BOOSTER_ALREADY_TAKEN]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_ALL_BOOSTER_TAKEN]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_BOOSTER_NOT_ACTIVE]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_FINALFIX_LOCKED]: "Something went wrong",
  [cf_user_team_finalfix_upd.EXP_SOME_ERROR_OCCURED_3]: "Something went wrong",
};

export const leagueMembersConstants = {
  ITEMS_PER_PAGE: 10,
};

export const fixtureMatchStatus = {
  "": 0,
  "": 1,
  POINT_CALCULATION_DONE_FOR_SPRINT_OR_QUALIFYING: 2,
  "": 3,
};

export const cf_user_league_join = {
  EXP_TOUR_IS_END: 1551,
  EXP_TEAM_NOT_FOUND: 1552,
  EXP_INVALID_LEAGUE_CODE: 1553,
  EXP_MEMBER_COUNT_EXCEEDED: 1554,
  EXP_LEAGUE_COUNT_EXCEEDED: 1555,
  EXP_LEAGUE_ALREADY_MEMBER: 1556,
  EXP_DUBLICATE_TEAM_NO_FOUND: 1557,
  EXP_TEAM_COUNT_NOT_MATCH: 1558,
  EXP_3MEM_ACHIVEMENT_ALREADY_UNLOCK: 1559,
  EXP_10MEM_ACHIVEMENT_ALREADY_UNLOCK: 1560,
  PROFANITY_CHECK: -71,
};

export const cf_user_league_join_error_messages = {
  "-1": "Something went wrong",
  [cf_user_league_join.EXP_TOUR_IS_END]: "Tournament has ended",
  [cf_user_league_join.EXP_TEAM_NOT_FOUND]: "Team not found",
  [cf_user_league_join.EXP_INVALID_LEAGUE_CODE]: "Invalid League code",
  [cf_user_league_join.EXP_MEMBER_COUNT_EXCEEDED]: "Max member count reached",
  [cf_user_league_join.EXP_LEAGUE_COUNT_EXCEEDED]: "Max leagues count reached",
  [cf_user_league_join.EXP_LEAGUE_ALREADY_MEMBER]:
    "You are already a part of this league",
  [cf_user_league_join.EXP_DUBLICATE_TEAM_NO_FOUND]:
    "You team is already a part of this league",
  [cf_user_league_join.EXP_TEAM_COUNT_NOT_MATCH]: "Team count mismatch",
  [cf_user_league_join.EXP_3MEM_ACHIVEMENT_ALREADY_UNLOCK]:
    "Achievement already unlocked",
  [cf_user_league_join.EXP_10MEM_ACHIVEMENT_ALREADY_UNLOCK]:
    "Achievement already unlocked",
};

export const cf_user_league_create = {
  EXP_TEAM_NOT_FOUND: 1501,
  EXP_LEAGUE_COUNT_EXCEEDED: 1502,
  EXP_LEAGUE_NAME_NULL: 1503,
  EXP_ACHIVEMENT_ALREADY_UNLOCK: 1504,
  EXP_TEAM_COUNT_NOT_MATCH: 1505,
  EXP_DUBLICATE_TEAM_NO_FOUND: 1506,
  PROFANITY_CHECK: -71,
  LEAGUE_NAME_MORE_THAN_50_CHARACTERS: 15,
};

export const cf_user_league_create_error_messages = {
  "-1": "Something went wrong",
  [cf_user_league_create.EXP_TEAM_NOT_FOUND]: "Team not found",
  [cf_user_league_create.EXP_LEAGUE_COUNT_EXCEEDED]:
    "Max leagues count reached",
  [cf_user_league_create.EXP_LEAGUE_NAME_NULL]: "Invalid League name",
  [cf_user_league_create.EXP_ACHIVEMENT_ALREADY_UNLOCK]:
    "Achievement already unlocked",
  [cf_user_league_create.EXP_TEAM_COUNT_NOT_MATCH]: "Team count mismatch",
  [cf_user_league_create.EXP_DUBLICATE_TEAM_NO_FOUND]:
    "You team is already a part of this league",
  [cf_user_league_create.PROFANITY_CHECK]: "Kindly enter a valid League name",
  [cf_user_league_create.LEAGUE_NAME_MORE_THAN_50_CHARACTERS]:
    "League name must not be more than 50 characters",
};

export const cf_user_league_create_error_headers = {
  [cf_user_league_create.PROFANITY_CHECK]: "League name not allowed",
};

export const cf_user_hth_league_join = {
  EXP_TOUR_IS_END: 1651,
  EXP_TEAM_NOT_FOUND: 1652,
  EXP_INVALID_LEAGUE_CODE: 1653,
  EXP_MEMBER_COUNT_EXCEEDED: 1654,
  EXP_LEAGUE_ALREADY_MEMBER: 1655,
};

export const cf_user_hth_league_join_error_messages = {
  "-1": "Something went wrong",
  [cf_user_hth_league_join.EXP_TOUR_IS_END]: "Tour has ended",
  [cf_user_hth_league_join.EXP_TEAM_NOT_FOUND]: "Team not found",
  [cf_user_hth_league_join.EXP_INVALID_LEAGUE_CODE]: "Invalid League code",
  [cf_user_hth_league_join.EXP_MEMBER_COUNT_EXCEEDED]: "Member count exceeded",
  [cf_user_hth_league_join.EXP_LEAGUE_ALREADY_MEMBER]: "League already joined",
};

export const F1_FANTASY_COOKIE = "F1_FANTASY_007";
export const TA_CMP_COOKIE_PREFERENCES = "cmapi_cookie_privacy";
export const TA_CMP_FUNCTIONAL_COOKIE_PERMIT = "2";

export const boosterIdKey = {
  1: "limitless",
  2: "wildcard",
  3: "finalFix",
  4: "autoPilot",
  5: "noNegative",
  6: "extraDRS",
};

export const getLeagueBadge = (key) => {
  const leagueBadgeList = {
    Popular: {
      matchStatusText:
        typeof getTranslations === "function" &&
        getTranslations("league_badge_popular", "Popular"),
      matchStatusIcon: "f1i-star-filled",
      matchStatusCls: "si-popular",
    },
    New: {
      matchStatusText:
        typeof getTranslations === "function" &&
        getTranslations("league_badge_new", "New"),
      matchStatusIcon: "f1i-new-tag",
      matchStatusCls: "si-new",
    },
    JustOpened: {
      matchStatusText: "Just Opened",
      matchStatusIcon: "f1i-live-timing",
      matchStatusCls: "si-justOpened",
    },
    LastChanceJoin: {
      matchStatusText:
        typeof getTranslations === "function" &&
        getTranslations("league_badge_last_chance", "Last Chance to Join"),
      matchStatusIcon: "f1i-timer",
      matchStatusCls: "si-lastChanceJoin",
    },
    LeagueEnded: {
      matchStatusText:
        typeof getTranslations === "function" &&
        getTranslations("league_badge_league_ended", "League Ended"),
      matchStatusIcon: "f1i-flag",
      matchStatusCls: "si-leagueEnded",
    },
  };

  return leagueBadgeList?.[key] ?? {};
};

export const leagueBadgeKeys = {
  1: "Popular",
  2: "New",
  3: "LastChanceJoin",
  4: "LeagueEnded",
};

export const REGISTER_LINK = `https://account.formula1.com/#/en/register`;
export const LOGIN_LINK = `https://account.formula1.com/#/en/login`;
export const DATA_REDIRECT_INTERNAL_VALUE = "internal";
export const DATA_REDIRECT_EXTERNAL_VALUE = "external";
