import { useCallback } from "react";

const useDataLayer = () => {
  const pushToDataLayer = useCallback((event, data = {}) => {
    // const { pageName, componentType } = data;
    const pageName = data?.pageName;
    const componentType = data?.componentType;
    window.dataLayer = window?.dataLayer || [];

    if (!event) {
      console.error("Error pushing data to data layer: event is falsy");
      return;
    }

    const datalayerObject = {
      event: event ? event : null,
      ...data,
      pageName: `Fantasy | ${pageName}`,
    };

    if (!!componentType) {
      datalayerObject.componentType = `Fantasy ${componentType}`;
    }

    try {
      window?.dataLayer?.push(datalayerObject);
    } catch (error) {
      console.error("Error pushing data to data layer:", error);
    }
  }, []);

  return pushToDataLayer;
};

export default useDataLayer;
