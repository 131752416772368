import React from "react";
import { useNavigate } from "react-router-dom";
import {
  CtaButton,
  MatchHeader,
  MatchHeaderThumbnail,
  MatchTimer,
} from "../../ReusableComponents/index";
import { useSelector, useDispatch } from "react-redux";
import { fixture } from "../../redux/actions/";
import {
  getPath,
  getTranslations,
  isAndroid,
  isIOS,
  isMobile,
} from "../../Common/utils";
import { gameplay } from "../../redux/actions/";
import SectionLoader from "../Loaders/SectionLoader";
import { currentFixture, getGDStatus } from "../../Common/utils/fixture";
import { isUserLoggedIn } from "../../Common/utils/user";
import useIPadOrientation from "../../Common/hooks/useIPadOrientation";
import {
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  INTERNAL,
  PORTRAIT,
  WEB,
  WEBVIEW,
} from "../../Common/constants";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";

const MatchHeaderMaster = (MatchHeaderMasterConfig) => {
  const {
    matchHeaderThumbnail = false,
    matchHeaderCta = false,
    matchHeaderMoreDetails = false,
    showCTA = false,
    currentSeasonYear = null,
  } = MatchHeaderMasterConfig;
  const navigate = useNavigate();
  const translationState = useSelector((state) => state.translation);
  const gameplayState = useSelector((state) => state.gameplay);
  const userState = useSelector((state) => state.user);
  const webview = userState?.webview;

  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();
  const pushToDataLayer = useDataLayer();

  const { isIPad, screenDimensionOrientation } = useIPadOrientation();

  const isIPadPortrait = isIPad && screenDimensionOrientation === PORTRAIT;
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const hasCreatedTeam = () => gameplayState?.teams?.length;

  const handleClick = () => {
    const dataLayerObject = {
      pageName: "Home",
      actionType: "CTA Click",
      pathType: INTERNAL,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };
    if (hasCreatedTeam()) {
      pushToDataLayer("manageYourTeam", {
        ...dataLayerObject,
        path: "/my-team",
      });
      navigate(getPath(translationState, "myTeam"));
    } else {
      pushToDataLayer("pickYourTeam", {
        ...dataLayerObject,
        path: "/create-team",
      });
      navigate(getPath(translationState, "createTeam"));
    }
  };
  const dispatch = useDispatch();
  const fixtureState = useSelector((state) => state.fixture);
  const getFixtures = () => {
    dispatch(fixture.fetchFixtures({ languageCode: "en" }));
  };
  if (!Object.keys(fixtureState).length) {
    getFixtures();
  }

  const toggleCircuitPopup = () => {
    dispatch(gameplay.toggleCircuitPopup());
  };

  const mainCTAText = () => {
    if (hasCreatedTeam()) {
      return getTranslations("landing_page_raceday_cta_2", "Manage Your Team");
    } else {
      return getTranslations("landing_page_raceday_cta", "Pick Your Team");
    }
  };

  const canShowMainCTA = () => {
    if (isUserLoggedIn(userState)) {
      return true;
    } else {
      return !!currentFixture(fixtureState)?.IsActiveForNewUser;
    }
  };

  return (
    <>
      <div className="si-main__row">
        <div className="si-cmo__header">
          {fixtureState?.loading ? <SectionLoader /> : ""}
          <div className="si-matchHeader__wrap">
            {!isIPadPortrait && matchHeaderThumbnail && (
              <MatchHeaderThumbnail />
            )}

            <div className="si-matchHeader__wrap-lft">
              <MatchHeader
                tagName="h1"
                fixture={currentFixture(fixtureState)}
                MatchHeaderConfig={{
                  MatchHeaderExtra:
                    getGDStatus(currentFixture(fixtureState)?.GDStatus) ===
                    "LIVE",
                  MatchStatusWrap:
                    getGDStatus(currentFixture(fixtureState)?.GDStatus) ===
                    "LIVE",
                  showCTA: showCTA,
                }}
              />
              {matchHeaderMoreDetails && !isMobile() && (
                <div
                  className="si-matchHeader__cta"
                  role="button"
                  aria-label={getTranslations(
                    "landing_page_raceday_more_desc",
                    "click on more details button to know more details about circuits"
                  )}
                >
                  <CtaButton
                    btnCls="si-btn si-btn__link"
                    iconCls=""
                    btnText={getTranslations(
                      "landing_page_raceday_more",
                      "More Details"
                    )}
                    ariaLabel={getTranslations(
                      "landing_page_raceday_more",
                      "More Details"
                    )}
                    onClickProp={toggleCircuitPopup}
                  />
                </div>
              )}
            </div>
            {getGDStatus(currentFixture(fixtureState)?.GDStatus) !== "LIVE" ? (
              <div className="si-matchHeader__wrap-rgt">
                <MatchTimer
                  extraCls={"eot"}
                  fixture={currentFixture(fixtureState)}
                />

                {matchHeaderCta && canShowMainCTA() && (
                  <div className="si-matchHeader__cta" role="button">
                    <CtaButton
                      btnCls="si-btn si-btn__primary"
                      iconCls="f1i-chevron-right"
                      btnText={mainCTAText()}
                      ariaLabel={mainCTAText()}
                      onClickProp={() => handleClick()}
                      btnState={disableCTAs}
                    />
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { MatchHeaderMaster };
