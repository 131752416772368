import axios from "axios";
import core from "../../Common/core";
import { session } from "./services";
import {
  CLIENT_ID,
  MIX_API_KEY,
  OPT_TYPE,
  PLATFORM_CATEGORY,
  PLATFORM_ID,
  PLATFORM_VERSION,
  RE_RENDER_LOCAL_STORAGE_KEY,
} from "../../Common/constants";
import { getCookie } from "../../Common/utils";
import { HOME_BUSTER, LEAGUE_BUSTER, TEAM_BUSTER } from "../../Common/busters";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // Buster Logic
    if (config.addBuster && config.busterCookieName) {
      // let buster = core.getCookie(config.busterCookieName);
      // if (!buster) {
      const ONE_MINUTE = 1000 * 60;
      const ONE_DAY = 1000 * 60 * 60 * 24;
      let buster = Date.now();
      core.setCookie(
        config.busterCookieName,
        buster,
        (5 * ONE_MINUTE) / ONE_DAY
      );
      // }
      config.params = {
        ...config.params,
        buster,
      };
    }

    // Buster Logic to bust related api calls
    if (config.addBusterIfAvailable && config.busterCookieName) {
      let buster = core.getCookie(config.busterCookieName);
      if (buster) {
        config.params = {
          ...config.params,
          buster,
        };
      } else {
        const ONE_MINUTE = 1000 * 60;
        const ONE_DAY = 1000 * 60 * 60 * 24;
        let buster = Date.now();
        core.setCookie(
          config.busterCookieName,
          buster,
          (5 * ONE_MINUTE) / ONE_DAY
        );

        config.params = {
          ...config.params,
          buster,
        };
      }
    }

    // Buster Logic to bust related api calls
    if (config.addBusterIfAvailable && !config.busterCookieName) {
      let buster = core.getCookie(config.busterCookieName);
      if (buster) {
        config.params = {
          ...config.params,
          buster,
        };
      }
    }

    // Buster for feeds
    if (config.feedBuster && getCookie(MIX_API_KEY)) {
      let buster = getCookie(MIX_API_KEY);
      config.params = {
        ...config.params,
        buster,
      };
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Check Maintenance
    if (response?.data?.Meta?.Maintenance) {
      window.si_bbu = !!Number(response?.data?.Meta?.Maintenance);
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response?.data?.Meta?.Success === false) {
      return Promise.reject({
        data: response?.data?.Meta,
      });
    }
    if (response.config.bustCookie && response.config.busterCookieName) {
      // Buster Logic
      const ONE_MINUTE = 1000 * 60;
      const ONE_DAY = 1000 * 60 * 60 * 24;
      const buster = Date.now();
      core.setCookie(
        response.config.busterCookieName,
        buster,
        (5 * ONE_MINUTE) / ONE_DAY
      );
      if (
        response.config.busterCookieName === TEAM_BUSTER ||
        response.config.busterCookieName === LEAGUE_BUSTER
      ) {
        const newHomeBuster = Date.now();
        core.setCookie(HOME_BUSTER, newHomeBuster, (5 * ONE_MINUTE) / ONE_DAY);
      }
      // To re-render game open in another tab
      localStorage.setItem(RE_RENDER_LOCAL_STORAGE_KEY, Date.now());
    }

    if (response?.data?.Data?.Value !== undefined) {
      response.data = response?.data?.Data?.Value;
    }
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // Check if error is because of expired session
    if (
      getCookie("login-session") &&
      ((error?.response?.status >= 300 && error?.response?.status < 400) ||
        error?.response?.status == 401) &&
      !error.config.retryApiCall
    ) {
      error.config.retryApiCall = true;
      // Call Fantasy Login API to get a valid session
      const payload = {
        optType: OPT_TYPE,
        platformId: PLATFORM_ID,
        platformVersion: PLATFORM_VERSION,
        platformCategory: PLATFORM_CATEGORY,
        clientId: CLIENT_ID,
      };
      if (!window.si_eosOfflineMode) {
        await session.login(payload);
      }
      // ? Save new session data
      // Retry original request
      return new Promise((resolve) => {
        resolve(instance(error.config));
      });
    }
    return Promise.reject(error);
  }
);

export default instance;
