import { TEAM_BUSTER } from "../../../Common/busters";
import { MIX_API_KEY, ONE_MINUTE } from "../../../Common/constants";
import { getCookie, setCookie } from "../../../Common/utils";
import {
  FETCH_MIX_API_PENDING,
  FETCH_MIX_API_REJECTED,
  FETCH_MIX_API_FULFILLED,
} from "../../constants/mixapi";

const mixapi = (state = {}, action) => {
  switch (action.type) {
    case FETCH_MIX_API_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_MIX_API_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_MIX_API_FULFILLED:
      let value = action.payload.data?.Value;

      if (!getCookie(MIX_API_KEY)) {
        setCookie(MIX_API_KEY, value?.ov, ONE_MINUTE * 5);
      }

      if (getCookie(MIX_API_KEY) && value?.ov != getCookie(MIX_API_KEY)) {
        setCookie(MIX_API_KEY, value?.ov, ONE_MINUTE * 5);
        setCookie(TEAM_BUSTER, Date.now());
      }

      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        data: value,
      };
    default:
      return state;
  }
};
export default mixapi;
