import {
  FETCH_DRIVERS_PENDING,
  FETCH_DRIVERS_REJECTED,
  FETCH_DRIVERS_FULFILLED,
  FETCH_RACE_WEEK_DRIVERS_PENDING,
  FETCH_RACE_WEEK_DRIVERS_REJECTED,
  FETCH_RACE_WEEK_DRIVERS_FULFILLED,
} from "../../constants/driver";

const driver = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DRIVERS_PENDING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        list: undefined,
      };
    case FETCH_DRIVERS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_DRIVERS_FULFILLED:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        list: action.payload.data,
        activePlayers: action.payload.data.filter(
          (driver) => driver?.IsActive !== "0"
        ),
      };
    case FETCH_RACE_WEEK_DRIVERS_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_RACE_WEEK_DRIVERS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_RACE_WEEK_DRIVERS_FULFILLED:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        selectedRaceWeek: action.payload.data,
      };
    default:
      return state;
  }
};
export default driver;
