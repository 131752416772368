import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Accordion,
  BoosterChips,
  CtaButton,
  ErrorMessage,
  Formations,
  LeagueHeader,
  MatchData,
  MiniLeague,
  NoDataFound,
  Overlays,
  PlayerBudget,
  PlayerInfo,
  ProvisionalPtsTooltip,
  PvtPubCardInfo,
  SponsorBanner,
  ToastNotification,
} from "..";
import LeagueSelectBox from "../../Components/League/LeagueSelectBox";
import LeagueSetting from "../../Components/League/LeagueSetting";
import LeagueShare from "../../Components/League/LeagueShare";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  EXTRA_TRANSFER_COST,
  FANTASY_ANDROID,
  FANTASY_IOS,
  FANTASY_WEBSITE,
  OPT_TYPE,
  WEB,
  WEBVIEW,
  leagueMembersConstants,
} from "../../Common/constants";
import {
  leaderboard,
  league,
  gameplay,
  driver,
  fixture,
} from "../../redux/actions";
import { LoadMoreCta } from "../LoadMoreCta";
import { TableHeader } from "../TableHeader";
import {
  getDefaultLeagueImageName,
  getLeagueConditionClsName,
  getLeagueImageName,
  getLeagueTeamID,
  getLeagueTypeId,
  getLeagueTypeName,
  getSponsoredLeagueImageSrc,
} from "../../Common/utils/league";
import {
  getLeagueTypeForDL,
  getPath,
  getPoints,
  getTranslations,
  getWebPurifyStatus,
  getYearForImagePath,
  isAndroid,
  isIOS,
  isMobile,
} from "../../Common/utils";
import AccordionChild from "../Accordion/AccordionChild";
import {
  currentFixture,
  getFixtureDateForLeagues,
  getMatchStatus,
  getMiniLeaguesLeaderBoardFilter,
  leaderBoardFixtures,
} from "../../Common/utils/fixture";
import useDataLayer from "../../Common/hooks/useDataLayer";
import useTournamentScanario from "../../Common/hooks/useTournamentScanario";
import HeadToHeadLeaderboard from "./HeadToHeadLeaderboard";
import SectionLoader from "../Loaders/SectionLoader";
import MyTeamHeader from "../../Components/MyTeam/MyTeamHeader";
import OppositeTeam from "./OppositeTeam";

const LeagueMembers = (tableBody) => {
  const { leagueId, type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state, pathname } = location;
  let [searchParams] = useSearchParams();
  const leagueState = useSelector((state) => state.league);
  const {
    leagueInfoLoading,
    leagueInfoSuccess,
    leagueInfoError,
    leagueInfoSuccessData,
  } = leagueState;
  const webConfigState = useSelector((state) => state.webconfig);
  const leaderBoardState = useSelector((state) => state.leaderboard);
  const userState = useSelector((state) => state.user);
  const driverState = useSelector((state) => state.driver);
  const translationState = useSelector((state) => state.translation);
  const fixtureState = useSelector((state) => state.fixture);
  const mixApiState = useSelector((state) => state.mixapi);

  /**
   * Opposite team logic
   */

  const gameplayState = useSelector((state) => state.gameplay);

  const {
    oppDetailedTeams,
    selectedOpponentBooster,
    fetchOppDetailedTeamLoading,
    oppUserGameDays,
    oppUserGameDaysSuccess,
  } = gameplayState;

  const { webConfigData = null } = webConfigState;
  const leaguesConfig = webConfigData?.leagues;
  const h2hInfoCaptionList = leaguesConfig?.h2hInfoCaptionList;

  const [oppTeamGamedayId, setOppTeamGamedayId] = useState(null);
  const [oppTeamNo, setOppTeamNo] = useState(null);
  const [oppTeamGUID, setOppTeamGUID] = useState(null);
  const [selectedTeamType, setSelectedTeamType] = useState("opposite");
  const oppositeTeamDetails = oppDetailedTeams?.[0];

  const { teamNo, phaseId, gameDayId } = useParams();

  const { leagueMembers, privateLeagues, success, selectedLeague } =
    leagueState;
  const {
    leaderBoard,
    userLeaderBoard,
    hasMore,
    leaderBoardSuccess,
    leagueInfo,
  } = leaderBoardState;

  const isProfanityEnabled = mixApiState?.data?.conf?.ipe;
  const isWebPurifyCalled = selectedLeague?.isWebPurifyCalled;
  const isSystemNameUpd = selectedLeague?.isSystemNameUpd;
  const webPurifyResponse = selectedLeague?.webPurifyresponse;

  const webPurifyStatus = getWebPurifyStatus(
    isWebPurifyCalled,
    isSystemNameUpd,
    webPurifyResponse
  );

  const [pageNo, setPageNo] = useState(1);
  const [optType, setOptType] = useState(1);
  const [selectedGameDay, setSelectedGameDay] = useState(undefined);
  const [selectedPhase, setSelectedPhase] = useState(1);

  const pushToDataLayer = useDataLayer();
  const { isEos, lastGamedayRaceLocked } = useTournamentScanario();

  const webview = userState?.webview;
  const disableCTAs = isEos || lastGamedayRaceLocked;

  const getUserPrivateLeaderBoard = () => {
    // opt type = 1--Overall
    // opt type = 2--Gameday
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      leagueId: leagueId,
      gameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
    };
    dispatch(leaderboard.getUserPrivateLeaderBoard(payload));
  };

  const getPublicLeaderBoard = () => {
    // opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      leagueId: leagueId,
      gameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
      tourGameDayId: selectedGameDay,
    };
    dispatch(leaderboard.getPublicLeaderBoard(payload));
  };

  const getUserPublicLeaderBoard = () => {
    // opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      leagueId: leagueId,
      gameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
      tourGameDayId: selectedGameDay,
    };
    dispatch(leaderboard.getUserPublicLeaderBoard(payload));
  };

  const getMiniLeagueLeaderBoard = () => {
    // opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      leagueId: leagueId,
      gameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
      tourGameDayId: selectedGameDay,
    };
    dispatch(leaderboard.getMiniLeagueLeaderBoard(payload));
  };

  const getUserMiniLeagueLeaderBoard = () => {
    // opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      leagueId: leagueId,
      gameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
      tourGamedayId: selectedGameDay,
    };
    dispatch(leaderboard.getUserMiniLeagueLeaderBoard(payload));
  };

  const getH2HLeaderBoard = () => {
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      leagueId: leagueId,
      gameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: leagueMembersConstants.ITEMS_PER_PAGE,
    };
    dispatch(leaderboard.getH2HLeaderBoard(payload));
  };

  const getGlobalLeaderBoard = () => {
    // opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      optType: optType,
      tourGameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getGlobalLeaderBoard(payload));
  };

  const getUnjoinedSponsoredPrivateLeaderBoard = () => {
    // opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      optType: optType,
      tourGameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: 10,
      leagueId: leagueId,
    };
    dispatch(leaderboard.getUnjoinedSponsoredPrivateLeaderBoard(payload));
  };

  const getUserGlobalLeaderBoard = () => {
    //     opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      tourGameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getUserGlobalLeaderBoard(payload));
  };

  const getFavoriteTeamLeaderBoard = () => {
    //     opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      optType: optType,
      teamId: leagueId,
      leagueId: leagueId,
      tourGameDayId: selectedGameDay,
      phaseId: selectedPhase,
      userGuid: userState?.data?.GUID,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getFavoriteTeamLeaderBoard(payload));
  };

  const getUserFavoriteTeamLeaderBoard = () => {
    //     opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      teamId: leagueId,
      tourGameDayId: selectedGameDay,
      phaseId: selectedPhase,
      userGuid: userState?.data?.GUID,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getUserFavoriteTeamLeaderBoard(payload));
  };

  const getCountryLeaderBoard = () => {
    //     opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      optType: optType,
      userGuid: userState?.data?.GUID,
      countryId: leagueId,
      leagueId: leagueId,
      tourGameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getCountryLeaderBoard(payload));
  };

  const getUserCountryLeaderBoard = () => {
    //     opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      userGuid: userState?.data?.GUID,
      countryId: leagueId,
      tourGamedayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getUserCountryLeaderBoard(payload));
  };

  const getDriverLeaderBoard = () => {
    //     opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      optType: optType,
      socialId: userState?.data?.SocialId,
      driverId: leagueId,
      leagueId: leagueId,
      tourGameDayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getDriverLeaderBoard(payload));
  };

  const getUserDriverLeaderBoard = () => {
    //     opt type = 1-- Gameday
    // opt type = 2-- Overall
    // opt type = 3-- Phase
    const payload = {
      guid: userState?.data?.GUID,
      optType: optType,
      socialId: userState?.data?.SocialId,
      driverId: leagueId,
      tourGamedayId: selectedGameDay,
      phaseId: selectedPhase,
      pageNo: pageNo,
      topNo: 10,
    };
    dispatch(leaderboard.getUserDriverLeaderBoard(payload));
  };

  const isPrivate = () =>
    getLeagueTypeId(selectedLeague?.leagueType) === 0 ||
    type === "private" ||
    getLeagueTypeId(selectedLeague?.leagueType) === 1 ||
    type === "h2h";

  const isVIP = () => getLeagueTypeId(selectedLeague?.leagueType) === 3;

  const isPublic = () =>
    getLeagueTypeId(selectedLeague?.leagueType) === 2 ||
    type === "public" ||
    selectedLeague?.leagueType === "Global" ||
    type === "global" ||
    selectedLeague?.leagueType === "Country" ||
    type === "country" ||
    selectedLeague?.leagueType === "Team" ||
    type === "team" ||
    selectedLeague?.leagueType === "Driver" ||
    type === "driver" ||
    selectedLeague?.leagueType === "Mini" ||
    type === "mini";

  const isHeadToHead =
    getLeagueTypeId(selectedLeague?.leagueType) === 1 || type === "h2h";

  const isMiniLeague = useCallback(
    () => selectedLeague?.leagueType === "Mini" || type === "mini",
    [type, selectedLeague]
  );

  const handleLoadMore = () => {
    if (
      getLeagueTypeId(selectedLeague?.leagueType) === 0 ||
      getLeagueTypeId(selectedLeague?.leagueType) === 3 ||
      type === "private"
    ) {
      if (selectedLeague?.isSponsor && !selectedLeague?.isJoined) {
        getUnjoinedSponsoredPrivateLeaderBoard();
      } else {
        getUserPrivateLeaderBoard();
      }
    }

    if (getLeagueTypeId(selectedLeague?.leagueType) === 1 || type === "h2h") {
      // getH2HLeaderBoard();
    }

    if (
      getLeagueTypeId(selectedLeague?.leagueType) === 2 ||
      type === "public"
    ) {
      getPublicLeaderBoard();
      getUserPublicLeaderBoard();
    }

    if (selectedLeague?.leagueType === "Global" || type === "global") {
      getGlobalLeaderBoard();
      getUserGlobalLeaderBoard();
    }

    if (selectedLeague?.leagueType === "Country" || type === "country") {
      getCountryLeaderBoard();
      getUserCountryLeaderBoard();
    }

    if (selectedLeague?.leagueType === "Team" || type === "team") {
      getFavoriteTeamLeaderBoard();
      getUserFavoriteTeamLeaderBoard();
    }

    if (selectedLeague?.leagueType === "Driver" || type === "driver") {
      getDriverLeaderBoard();
      getUserDriverLeaderBoard();
    }

    if (getLeagueTypeId(selectedLeague?.leagueType) === 4 || type === "mini") {
      getMiniLeagueLeaderBoard();
      getUserMiniLeagueLeaderBoard();
    }
  };

  useEffect(() => {
    if (!selectedLeague) {
      navigate(getPath(translationState, "leagues"));
    }
    if (isPrivate() || isVIP()) {
      setOptType(1);
      setSelectedGameDay(1);
      setSelectedPhase(1);
    }
    if (isPublic()) {
      setOptType(2);
      setSelectedGameDay(0);
      setSelectedPhase(1);
    }

    if (state?.leagueCode) {
      dispatch(league.getLeagueInfo({ leagueCode: state.leagueCode }));
    }

    return () => {
      dispatch(leaderboard.resetLeaderBoard());
    };
  }, []);

  useEffect(() => {
    if (leaderBoardSuccess) {
      setPageNo((prev) => prev + 1);
    }
  }, [leaderBoardSuccess]);

  const PvtPubCardConfig = {
    showPvtPubCardChips: !isGlobalPublicLeague(),
    conditionClsName: getLeagueTypeId(selectedLeague?.leagueType),
    leagueName: selectedLeague?.leagueName || leagueInfo?.leagueName,
    leagueCount: leagueInfo?.memCount || selectedLeague?.memberCount,
    maxLeagueCount:
      selectedLeague?.maxMembers === 99999999
        ? getTranslations("league_card_total_team_unlimited", "Unlimited")
        : selectedLeague?.maxMembers,
    showLeagueCount: true,
    type: selectedLeague?.leagueVIPFlag
      ? 3
      : getLeagueTypeId(selectedLeague?.leagueType),
    adminIcon: selectedLeague?.isSponsor
      ? false
      : selectedLeague?.leagueAdmin || selectedLeague?.isAdmin,
    errorIcon:
      !!selectedLeague?.leagueReportFlg || !!selectedLeague?.isReportFlag,
    conditionClsName: getLeagueConditionClsName(selectedLeague?.leagueType),
    showThumb:
      selectedLeague?.leagueVIPFlag || selectedLeague?.isSponsor
        ? true
        : getLeagueImageName(
            selectedLeague?.leagueType,
            selectedLeague?.countryCode,
            leagueId,
            webConfigData
          )
        ? true
        : false,
    imageName:
      selectedLeague?.leagueVIPFlag || selectedLeague?.isSponsor
        ? `${leagueId}.png`
        : getLeagueImageName(
            selectedLeague?.leagueType,
            selectedLeague?.countryCode,
            leagueId,
            webConfigData
          ),
    defaultImageName: selectedLeague?.isSponsor
      ? "/0.png"
      : getDefaultLeagueImageName(selectedLeague?.leagueType, webConfigData),
    teamID: getLeagueTeamID(selectedLeague?.leagueType, leagueId, driverState),
    imageSrc: selectedLeague?.leagueVIPFlag
      ? "vip-leagues/logo/"
      : selectedLeague?.isSponsor
      ? `${getSponsoredLeagueImageSrc(selectedLeague?.leagueType, "logo")}/`
      : "",
    showProfanityIcon:
      (selectedLeague?.leagueAdmin || selectedLeague?.isAdmin) &&
      isProfanityEnabled &&
      webPurifyStatus,
    webPurifyStatus,
    showMaxTeams: true,
    maxTeams: selectedLeague?.teamCount,
    userJoinedTeams: selectedLeague?.teamNo?.length,
    leagueBadgeId: selectedLeague?.disableonList ? 4 : selectedLeague?.tag?.id,
    leagueType: selectedLeague?.leagueType,
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const updateActiveIndex = (index, value) => {
    if (!value) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(index);
    }
  };

  function isGlobalPublicLeague() {
    return (
      type === "global" ||
      type === "country" ||
      type === "team" ||
      type === "driver"
    );
  }

  const getLeaderBoardFixtures = useCallback(() => {
    return isMiniLeague()
      ? getMiniLeaguesLeaderBoardFilter(fixtureState, selectedLeague?.raceweek)
      : isHeadToHead
      ? getMiniLeaguesLeaderBoardFilter(
          {
            list: fixtureState?.list?.filter(
              (x) =>
                x.SessionType === "Race" &&
                ["PC DONE", "PROVISIONAL POINTS"].includes(
                  getMatchStatus(x.MatchStatus)
                )
            ),
          },
          [
            ...new Set(
              fixtureState?.list
                .filter(
                  (fixture) =>
                    fixture?.RaceDay >= selectedLeague?.battleStartMatchday
                )
                .map((x) => x.GamedayId)
            ),
          ]
        )
      : leaderBoardFixtures(fixtureState);
  }, [fixtureState, isMiniLeague, selectedLeague, isHeadToHead]);

  const LeagueSelectField = useMemo(() => {
    return [
      {
        id: 0,
        value: getTranslations("league_memebers_filter_all_races", "All Races"),
      },
      ...getLeaderBoardFixtures(),
    ];
  }, [getLeaderBoardFixtures]);
  const [selectedOption, setSelectedOption] = useState(LeagueSelectField[0]);

  useEffect(() => {
    if (isPrivate()) {
      setOptType(selectedOption?.id > 0 ? 2 : 1);
      setSelectedGameDay(selectedOption?.id > 0 ? selectedOption?.id : 0);
      setSelectedPhase(1);
    }
    if (isPublic()) {
      setOptType(selectedOption?.id > 0 ? 1 : 2);
      setSelectedGameDay(selectedOption?.id > 0 ? selectedOption?.id : 0);
      setSelectedPhase(1);
    }
  }, [selectedOption]);

  useEffect(() => {
    const leagueType = getLeagueTypeName(selectedLeague?.leagueType);
    const getRaceWeekDrivers = () => {
      if (!selectedOption || leagueType === "h2h") return;
      const payload = {
        gameDayId: selectedOption?.id
          ? selectedOption?.id
          : LeagueSelectField?.[LeagueSelectField?.length - 1]?.id,
        languageCode: "en",
      };
      if (payload?.gameDayId === 0) {
        return;
      }
      dispatch(driver.fetchRaceWeekDrivers(payload));
    };
    getRaceWeekDrivers();
  }, [selectedOption, LeagueSelectField, dispatch, selectedLeague]);

  useEffect(() => {
    if (selectedGameDay !== undefined) {
      dispatch(leaderboard.resetLeaderBoard());
      handleLoadMore();
    }
  }, [selectedGameDay]);

  const meetingFixtures = fixtureState?.list?.filter(
    (x) =>
      x?.GamedayId ==
      (selectedOption?.id
        ? selectedOption?.id
        : LeagueSelectField?.[LeagueSelectField?.length - 1]?.id)
  );
  const fixtureStart =
    meetingFixtures?.length && meetingFixtures[0]?.SessionStartDate;
  const fixtureEnd =
    meetingFixtures?.length &&
    meetingFixtures[meetingFixtures?.length - 1]?.SessionEndDate;

  const transfersLeft = oppositeTeamDetails?.usersubsleft;
  const hasMadeExtraTransfers = transfersLeft < 0;

  const playerCardConfig = {
    playerType: webConfigData?.imagePaths?.driversFront?.replace(
      "{seasonYear}",
      getYearForImagePath(webConfigData)
    ),
    ctaBtn: false,
    teamLogo: false,
    jersyNo: false,
    playerStatsInList: false,
    playerTrendsLabel: false,
    ctaAppendClass: "si-btn__link",
    nameType: "short",
  };

  const pushRaceWeekSelectDataLayer = (raceWeek, clickText) => {
    const leagueType = getLeagueTypeName(selectedLeague?.leagueType);

    const dataLayerObject = {
      pageName: "Leagues",
      actionType: `Race Selection | ${raceWeek}`,
      leagueType: !!selectedLeague?.leagueVIPFlag
        ? "VIP"
        : getLeagueTypeForDL(leagueType),
      clickText,
      path: pathname,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("leagueLeaderboard", dataLayerObject);
  };

  const pushLeagueSettingsDataLayer = () => {
    const leagueType = getLeagueTypeName(selectedLeague?.leagueType);

    const dataLayerObject = {
      actionType: "League Settings",
      leagueType: !!selectedLeague?.leagueVIPFlag
        ? "VIP"
        : getLeagueTypeForDL(leagueType),
      pageName: "Leagues",
      path: getPath(translationState, "leaguesManage", {
        type: type,
        leagueId: leagueId,
      }),
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("leagueLeaderboard", dataLayerObject);
  };

  const handleRaceWeekSelect = (e) => {
    setPageNo(1);
    setSelectedOption(
      LeagueSelectField.find((x) => x.value === e.target.value)
    );
    pushRaceWeekSelectDataLayer(e.target.value, selectedOption?.value);
  };

  const handleJoinPublicLeague = () => {
    navigate(
      getPath(translationState, "selectedJoinLeague", {
        code: state.leagueCode,
      })
    );
  };

  const showLeagueSetting = () => {
    if (selectedLeague?.leagueType === "Mini") {
      return false;
    }
    if (state?.leagueCode) {
      return false;
    }
    return true;
  };

  const [showMiniLeagueInfo, setShowMiniLeagueInfo] = useState(false);

  function toggleMiniLeagueInfo(show = false) {
    setShowMiniLeagueInfo(show);
  }

  const [showH2HLeagueInfo, setShowH2HLeagueInfo] = useState(false);

  function toggleH2HLeagueInfo(show = false) {
    setShowH2HLeagueInfo(show);
  }

  const pushJoinPublicLeagueDataLayer = () => {
    const dataLayerObject = {
      actionType: "leagueSelect",
      clickText: getTranslations(
        "league_members_join_league_button",
        "Join League"
      ),
      path: `/leagues/join/${state?.leagueCode}`,
      pageName: "Leagues",
      leagueType: "Public",
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    pushToDataLayer("joinLeague", dataLayerObject);
  };

  const pushBackButtonClickDataLayer = () => {
    const leagueType = getLeagueTypeName(selectedLeague?.leagueType);

    const dataLayerObject = {
      actionType: "Back",
      clickText: getTranslations("manage_team_back_desc", "Click to go back"),
      pageName: "Leagues",
      leagueType: !!selectedLeague?.leagueVIPFlag
        ? "VIP"
        : getLeagueTypeForDL(leagueType),
      raceName: currentFixture(fixtureState)?.MeetingOfficialName,
      platform: isAndroid()
        ? FANTASY_ANDROID
        : isIOS()
        ? FANTASY_IOS
        : FANTASY_WEBSITE,
      componentType: webview ? WEBVIEW : WEB,
    };

    if (state?.fromRoute) {
      pushToDataLayer("joinLeague", {
        ...dataLayerObject,
        path: state?.fromRoute,
      });
    } else {
      pushToDataLayer("joinLeague", {
        ...dataLayerObject,
        path: getPath(translationState, "leagues"),
      });
    }
  };

  // * Fetch the opposite team when the opposite usergamedays is successful
  useEffect(() => {
    const getOppositeDetailedTeam = () => {
      if (
        !oppTeamNo ||
        !oppTeamGUID ||
        isNaN(selectedOption?.id) ||
        !driverState?.list ||
        !driverState?.selectedRaceWeek ||
        !oppUserGameDaysSuccess
      ) {
        return;
      }

      const payload = {
        opponentGuid: oppTeamGUID,
        optType: OPT_TYPE,
        teamNo: oppTeamNo,
        tourGamedayId: selectedOption?.id
          ? selectedOption?.id
          : LeagueSelectField?.[LeagueSelectField?.length - 1]?.id,
        phaseId: 1,
      };

      dispatch(
        gameplay.fetchOppDetailedTeam({
          payload,
          drivers: driverState?.selectedRaceWeek,
          selectedTeam: oppTeamNo - 1,
        })
      );
    };

    getOppositeDetailedTeam();
  }, [
    dispatch,
    oppTeamGUID,
    oppTeamNo,
    driverState,
    LeagueSelectField,
    selectedOption,
    oppUserGameDaysSuccess,
  ]);

  useEffect(() => {
    if (
      !leaderBoard ||
      !leaderBoard?.length ||
      isMobile() ||
      !driverState?.list ||
      !driverState?.selectedRaceWeek
    ) {
      return;
    }
    const topLeaderboardPlayer = leaderBoard?.[0];

    const topLeaderboardTeamNo =
      topLeaderboardPlayer?.teamno || topLeaderboardPlayer?.teamNo;

    setOppTeamNo(topLeaderboardTeamNo);
    setOppTeamGUID(topLeaderboardPlayer?.guid);

    const getOppUserGameDays = () => {
      if (!topLeaderboardTeamNo || !topLeaderboardPlayer?.guid) {
        return;
      }
      const payload = {
        guid: topLeaderboardPlayer?.guid,
        optType: OPT_TYPE,
        teamNo: topLeaderboardTeamNo,
      };
      dispatch(gameplay.fetchOppUserGameDays(payload));
    };

    getOppUserGameDays();
  }, [leaderBoard, dispatch, driverState]);

  const handleLeaderboardClick = (data, type) => {
    setSelectedTeamType(type);
    if (isNaN(selectedOption?.id)) return;
    if (isMobile()) {
      navigate(
        getPath(translationState, "detailedOppTeam", {
          gameDayId: selectedOption?.id,
          phaseId: 1,
          teamNo: data?.teamno || data?.teamNo,
        }),
        {
          state: {
            ...data,
            fromRoute: location?.pathname,
            raceWeeks: selectedLeague?.raceweek,
          },
        }
      );
      return;
    } else {
      setOppTeamGamedayId(
        selectedOption?.id
          ? selectedOption?.id
          : LeagueSelectField?.[LeagueSelectField?.length - 1]?.id
      );
      const selectedOppTeamNo = data?.teamno || data?.teamNo;

      setOppTeamNo(selectedOppTeamNo);
      setOppTeamGUID(data?.guid);

      const getOppUserGameDays = () => {
        if (!selectedOppTeamNo || !data?.guid) {
          return;
        }
        const payload = {
          guid: data?.guid,
          optType: OPT_TYPE,
          teamNo: selectedOppTeamNo,
        };
        dispatch(gameplay.fetchOppUserGameDays(payload));
      };

      getOppUserGameDays();
    }
  };

  const [h2hActiveTab, setH2HActiveTab] = useState(
    searchParams.get("st") === "2" ? 2 : 1
  );
  const handleTabClick = (tab) => {
    setH2HActiveTab(tab);
  };

  const selectedOppositeTeamName = useMemo(() => {
    if (selectedTeamType === "opposite") {
      return leaderBoard?.find(
        (currentTeam) => currentTeam?.teamName === oppositeTeamDetails?.teamname
      )?.teamName;
    }
    if (selectedTeamType === "user") {
      return userLeaderBoard?.find((team) => {
        const userTeamName = team?.teamName;
        const selectedTeamname = oppositeTeamDetails?.teamname;
        return userTeamName === selectedTeamname;
      })?.teamName;
    }
  }, [leaderBoard, oppositeTeamDetails, selectedTeamType, userLeaderBoard]);

  const leaderboardTableConfig = [
    { head: "Rank", cls: "rank", key: "rank" },
    { head: "Name", cls: "name", key: "teamName" },
    { head: "Points", cls: "pts", key: "points" },
  ];

  const userRankTableConfig = [
    {
      head: "Rank",
      cls: "rank",
      key: "rank",
    },
    {
      head: "Name",
      cls: "name",
      key: "teamName",
    },
    {
      head: "Points",
      cls: "pts",
      key: "points",
    },
  ];

  if (!!selectedOppositeTeamName) {
    const rightIconTableConfig = {
      head: "",
      cls: "icon",
      key: "rightIcon",
    };
    leaderboardTableConfig.push(rightIconTableConfig);
    userRankTableConfig.push(rightIconTableConfig);
  }

  const userTeamsLength = userLeaderBoard?.length ?? 0;

  /**
   * * 628px - height of RHS with additional transfers
   * * 76px - height of accordion without the team
   * * 52px - height of each team row
   * * 16px - padding top and bottom
   */
  const leaderboardTableStyles = isMobile()
    ? {}
    : {
        overflow: "auto",
        minHeight: "auto",
        maxHeight: !!userTeamsLength
          ? 628 - 76 - 52 * userTeamsLength - 16
          : 628,
      };

  return (
    <>
      <div className="si-league__member-wrap">
        <div className="si-main__container">
          <div className="si-main__row">
            {(!!selectedLeague?.leagueVIPFlag ||
              !!selectedLeague?.isSponsor) && (
              <SponsorBanner
                baseURL={
                  selectedLeague?.leagueVIPFlag
                    ? "vip-leagues/banner"
                    : selectedLeague?.isSponsor
                    ? getSponsoredLeagueImageSrc(
                        selectedLeague?.leagueType,
                        "banner"
                      )
                    : null
                }
                mobileBaseURL={
                  selectedLeague?.leagueVIPFlag
                    ? "vip-leagues/mobile-banner"
                    : selectedLeague?.isSponsor
                    ? getSponsoredLeagueImageSrc(
                        selectedLeague?.leagueType,
                        "mobile-banner"
                      )
                    : null
                }
                VipSponsorBannerImage={`${leagueId}.png`}
                showPin={false}
                bannerInternalUrl={selectedLeague?.bannerInternalUrl}
                bannerUrl={selectedLeague?.bannerUrl}
              />
            )}
            {selectedLeague?.leagueType === "Mini" && (
              <SponsorBanner
                baseURL="mini-leagues/banner"
                mobileBaseURL="mini-leagues/mobile-banner"
                VipSponsorBannerImage={`${leagueId}.png`}
                isVipSponsorMobileBannerImage={`${leagueId}.png`}
                bannerUrl={selectedLeague?.bannerUrl}
                bannerInternalUrl={selectedLeague?.bannerInternalUrl}
                bannerUrlFlag={selectedLeague?.bannerUrlFlag}
                leagueName={selectedLeague?.leagueName}
                showPin={false}
              />
            )}
            <LeagueHeader
              titleComponent={
                <>
                  <PvtPubCardInfo
                    PvtPubCardConfig={{
                      ...PvtPubCardConfig,
                      showProvisionalPts:
                        selectedOption?.id === 0 ||
                        selectedOption?.id ===
                          currentFixture(fixtureState)?.GamedayId,
                    }}
                  />
                </>
              }
              component={
                <>
                  <LeagueSelectBox
                    options={LeagueSelectField}
                    selectedOption={selectedOption}
                    handleChange={handleRaceWeekSelect}
                    extraClass={
                      isHeadToHead && h2hActiveTab === 1 ? "si-disabled" : ""
                    }
                  />
                  {isHeadToHead && (
                    <div className="si-league__mini-info">
                      <CtaButton
                        btnCls="si-btn si-btn__icon"
                        iconCls="f1i-info"
                        ariaLabel={getTranslations(
                          "league_hth_info_aria_label",
                          "How to play the new head to head league" // TODO: Add key for translations
                        )}
                        onClickProp={() => toggleH2HLeagueInfo(true)}
                      />
                    </div>
                  )}
                  {!isGlobalPublicLeague() &&
                    !selectedLeague?.leagueVIPFlag &&
                    !selectedLeague?.isSponsor &&
                    selectedLeague?.leagueType !== "Mini" && (
                      <>
                        {!!selectedLeague?.leagueCode && (
                          <LeagueShare
                            leagueName={
                              leagueInfo?.leagueName ||
                              selectedLeague?.leagueName
                            }
                            leagueType={getLeagueTypeId(
                              selectedLeague?.leagueType
                            )}
                            leagueCode={selectedLeague?.leagueCode}
                            leagueId={selectedLeague?.leagueId}
                            maxTeams={selectedLeague?.noOfteam}
                          />
                        )}
                        {leagueInfoLoading
                          ? ""
                          : // !!leagueInfoSuccessData?.extno ||
                            showLeagueSetting() && (
                              <LeagueSetting
                                type={type}
                                leagueId={leagueId}
                                pushLeagueSettingsDataLayer={
                                  pushLeagueSettingsDataLayer
                                }
                              />
                            )}
                        {state?.leagueCode &&
                          leagueInfoSuccessData &&
                          (!leagueInfoSuccessData?.extno ||
                            (leagueInfoSuccessData?.extno !== undefined &&
                              userState?.data?.TeamCount !==
                                leagueInfoSuccessData?.extno?.length &&
                              leagueInfoSuccessData?.extno?.length <
                                leagueInfoSuccessData?.noOfteam)) && (
                            <CtaButton
                              btnCls="si-btn si-btn__primary"
                              btnText={getTranslations(
                                "league_members_join_league_button",
                                "Join League"
                              )}
                              ariaLabel={getTranslations(
                                "league_members_join_league_button",
                                "Join League"
                              )}
                              btnState={disableCTAs}
                              onClickProp={() => {
                                pushJoinPublicLeagueDataLayer();
                                handleJoinPublicLeague();
                              }}
                            />
                          )}
                      </>
                    )}
                  {leagueInfoLoading ? null : selectedLeague?.leagueType ===
                    "Mini" ? (
                    <div className="si-league__mini-info">
                      <CtaButton
                        btnCls="si-btn si-btn__icon"
                        iconCls="f1i-info"
                        ariaLabel={getTranslations(
                          "league_landing_mini_league_join_cta",
                          "Join"
                        )}
                        onClickProp={() => toggleMiniLeagueInfo(true)}
                      />
                    </div>
                  ) : null}
                </>
              }
              hasBackButton={true}
              hasCustomBackAction={true}
              onBackButtonClick={() => {
                pushBackButtonClickDataLayer();
                if (state?.fromRoute) {
                  navigate(state?.fromRoute);
                } else {
                  navigate(-1);
                  dispatch(gameplay.resetOppDetailedTeam());
                  // navigate(getPath(translationState, "leagues"));
                }
              }}
            />
          </div>
          {isHeadToHead ? (
            <>
              <HeadToHeadLeaderboard
                activeTab={h2hActiveTab}
                handleTabClick={handleTabClick}
                optType={optType}
                gameDayId={selectedGameDay}
                phaseId={selectedPhase}
              />
            </>
          ) : (
            <>
              <div className="si-league__classic-wrap">
                <div className="si-league__classic-grid">
                  <div className="si-league__classic-gridLHS">
                    {getLeagueTypeId(selectedLeague?.leagueType) !== 1 &&
                      !!userLeaderBoard?.length && (
                        <div className="si-main__row">
                          <div className="si-league__member-box si-league__member-box--self">
                            <Accordion
                              activeIndex={activeIndex}
                              accordionChild={
                                <AccordionChild
                                  isActive={activeIndex === 0}
                                  updateActiveIndex={updateActiveIndex}
                                  index={0}
                                  accordionConfig={{ showAccordRHS: false }}
                                  headComponentLHS={
                                    <>
                                      <h2 className="si-accordion__header">
                                        <span>
                                          {getTranslations(
                                            "league_members_my_team_ranks_title",
                                            "My Team Ranks"
                                          )}
                                        </span>
                                      </h2>
                                    </>
                                  }
                                  bodyComponent={
                                    <>
                                      <div className="si-league__member-tbl">
                                        <TableHeader
                                          tableConfig={userRankTableConfig}
                                          tableData={userLeaderBoard}
                                          handleClick={(data) =>
                                            handleLeaderboardClick(data, "user")
                                          }
                                          selectedOppositeTeamName={
                                            selectedOppositeTeamName
                                          }
                                          selectedOption={selectedOption}
                                        />
                                      </div>
                                    </>
                                  }
                                />
                              }
                            />
                          </div>
                        </div>
                      )}
                    {type === "h2h" || !!leaderBoard?.length ? (
                      <div
                        className="si-league__member-box"
                        style={leaderboardTableStyles}
                      >
                        <div className="si-league__member-tbl">
                          <TableHeader
                            tableConfig={leaderboardTableConfig}
                            tableData={leaderBoard}
                            selectedOption={selectedOption}
                            raceWeeks={selectedLeague?.raceweek}
                            handleClick={(data) =>
                              handleLeaderboardClick(data, "opposite")
                            }
                            selectedOppositeTeamName={selectedOppositeTeamName}
                            showNewLeaderIcon={
                              selectedOption?.value === "All Races"
                                ? true
                                : false
                            }
                          />
                        </div>
                        {hasMore && (
                          <div className="si-main__row">
                            <div className="si-league__member-cta">
                              <LoadMoreCta
                                handleLoadMore={() => handleLoadMore()}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <NoDataFound
                        noDataCaption={getTranslations(
                          "leaderboard_no_data_found_title",
                          "No leaderboard found"
                        )}
                      />
                    )}
                  </div>
                  {!isMobile() && (
                    <div className="si-league__classic-gridRHS">
                      {!isHeadToHead &&
                        (!!oppDetailedTeams?.length ? (
                          <OppositeTeam
                            fetchOppDetailedTeamLoading={
                              fetchOppDetailedTeamLoading
                            }
                            oppositeTeamDetails={oppositeTeamDetails}
                            teamNo={teamNo}
                            meetingFixtures={meetingFixtures}
                            fixtureStart={fixtureStart}
                            fixtureEnd={fixtureEnd}
                            selectedOpponentBooster={selectedOpponentBooster}
                            selectedOption={selectedOption}
                            hasMadeExtraTransfers={hasMadeExtraTransfers}
                            transfersLeft={transfersLeft}
                            playerCardConfig={playerCardConfig}
                          />
                        ) : (
                          <NoDataFound
                            noDataCaption={getTranslations(
                              "opponent_no_data_found_title",
                              "No opponent team found"
                            )}
                          />
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Display the opposite team here */}

      {showMiniLeagueInfo && (
        <Overlays
          overlayName="si-popup__wrap--mini"
          overlayTitle={getTranslations(
            "create_mini_league_overlay_title_2",
            "Mini League"
          )}
          btnSecondary={getTranslations(
            "create_mini_league_completed_close_button",
            "Close"
          )}
          overlayConfig={{
            cancelBtn: true,
            submitBtn: false,
            showOverlaySub_Title: true,
          }}
          component={<MiniLeague leagueData={selectedLeague} />}
          onCancelClick={() => toggleMiniLeagueInfo(false)}
        />
      )}
      {showH2HLeagueInfo && (
        <ToastNotification
          type={"alert"}
          alertText={getTranslations(
            "league_hth_info_title",
            "Popup Message With Content"
          )}
          alertCaption={
            <ul>
              {h2hInfoCaptionList?.map((listItem, index) => {
                return <li key={index}>{getTranslations(listItem)}</li>;
              })}
            </ul>
          }
          isAlertCaptionHtml={true}
          handleClose={() => setShowH2HLeagueInfo(false)}
        />
      )}
    </>
  );
};

export { LeagueMembers };
