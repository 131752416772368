import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gameplay } from "../../redux/actions";
import { OPT_TYPE } from "../../Common/constants";
import { currentFixture } from "../../Common/utils/fixture";
import {
  getH2HCurrentUserTeam,
  getH2HOpponentUserTeam,
} from "../../Common/utils/league";

function useBattleMode({ opponent, user }) {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);
  const driverState = useSelector((state) => state.driver);
  const fixtureState = useSelector((state) => state.fixture);

  useEffect(() => {
    const getOppUserGameDays = () => {
      const payload = {
        guid: opponent?.userGuid,
        optType: OPT_TYPE,
        teamNo: opponent?.teamNo,
      };
      dispatch(gameplay.fetchH2HOppUserGameDays(payload));
    };

    getOppUserGameDays();
  }, [opponent, dispatch]);

  useEffect(() => {
    if (gameplayState?.h2hOppUserGameDaysSuccess) {
      const payload = {
        opponentGuid: opponent?.userGuid,
        optType: OPT_TYPE,
        teamNo: opponent?.teamNo,
        tourGamedayId: currentFixture(fixtureState)?.GamedayId,
        phaseId: currentFixture(fixtureState)?.PhaseId,
      };
      dispatch(
        gameplay.fetchH2HOppTeam({
          payload,
          drivers: driverState?.list,
          selectedTeam: opponent?.teamNo - 1,
        })
      );
    }
  }, [
    gameplayState?.h2hOppUserGameDaysSuccess,
    dispatch,
    opponent,
    fixtureState,
    driverState,
  ]);

  if (
    !gameplayState?.fetchH2HOppTeamSuccess ||
    !gameplayState?.h2hOppUserGameDaysSuccess
  )
    return {
      captains: {},
      drivers: [],
      constructors: [],
      finalFixNewPlayer: {},
      userTeam: {},
      opponentTeam: {},
    };

  const team1 = getH2HCurrentUserTeam(gameplayState, user);
  const team2 = getH2HOpponentUserTeam(gameplayState);

  const team1Captain = team1.selectedCaptain;
  const team2Captain = team2.selectedCaptain;

  function driverFilter(driver, team) {
    // Final Fix new driver is also the captain edge case
    if (
      team?.selectedCaptain?.PlayerId == team?.finalfxnewplayerid &&
      driver.isFinalFixOldPlayer
    ) {
      return false;
    }
    return (
      driver.Skill === 1 &&
      !driver.isCaptain &&
      driver.PlayerId != team.finalfxnewplayerid
    );
  }

  function constructorFilter(constructor) {
    return constructor.Skill === 2;
  }

  const commonDrivers = team1.team
    .filter((team) => driverFilter(team, team1))
    .filter((driver) =>
      team2.team
        .filter((team) => driverFilter(team, team2))
        .map((d) => d.PlayerId)
        .includes(driver.PlayerId)
    )
    .map((x) => x.PlayerId);

  const differentDriversOfTeam1 = team1.team
    .filter((team) => driverFilter(team, team1))
    .filter((driver) => {
      return !team2.team
        .filter((team) => driverFilter(team, team2))
        .map((d) => d.PlayerId)
        .includes(driver.PlayerId);
    });

  const differentDriversOfTeam2 = team2.team
    .filter((team) => driverFilter(team, team2))
    .filter(
      (driver) =>
        !team1.team
          .filter((team) => driverFilter(team, team1))
          .map((d) => d.PlayerId)
          .includes(driver.PlayerId)
    );

  const commonConstructors = team1.team
    .filter(constructorFilter)
    .filter((constructor) =>
      team2.team
        .filter(constructorFilter)
        .map((c) => c.PlayerId)
        .includes(constructor.PlayerId)
    );

  const differentConstructorsOfTeam1 = team1.team
    .filter(constructorFilter)
    .filter(
      (constructor) =>
        !team2.team
          .filter(constructorFilter)
          .map((c) => c.PlayerId)
          .includes(constructor.PlayerId)
    );

  const differentConstructorsOfTeam2 = team2.team
    .filter(constructorFilter)
    .filter(
      (constructor) =>
        !team1.team
          .filter(constructorFilter)
          .map((c) => c.PlayerId)
          .includes(constructor.PlayerId)
    );

  const finalFixNewDriverOfTeam1 = team1.team.find(
    (driver) => driver.PlayerId == team1.finalfxnewplayerid
  );

  const finalFixNewDriverOfTeam2 = team2.team.find(
    (driver) => driver.PlayerId == team2.finalfxnewplayerid
  );

  const finalFixOldDriverOfTeam1 = team1.team.find(
    (driver) => driver.PlayerId == team1.finalfxoldplayerid
  );

  const finalFixOldDriverOfTeam2 = team2.team.find(
    (driver) => driver.PlayerId == team2.finalfxoldplayerid
  );

  const userPlayers = {
    captain: team1Captain,
    drivers: [
      ...commonDrivers.map((x) => team1.team.find((t) => t.PlayerId === x)),
      ...differentDriversOfTeam1,
    ],
    constructors: [...commonConstructors, ...differentConstructorsOfTeam1],
    finalFixNewPlayer: finalFixNewDriverOfTeam1,
    finalFixOldPlayer: finalFixOldDriverOfTeam1,
  };

  const opponentPlayers = {
    captain: team2Captain,
    drivers: [
      ...commonDrivers.map((x) => team2.team.find((t) => t.PlayerId === x)),
      ...differentDriversOfTeam2,
    ],
    constructors: [...commonConstructors, ...differentConstructorsOfTeam2],
    finalFixNewPlayer: finalFixNewDriverOfTeam2,
    finalFixOldPlayer: finalFixOldDriverOfTeam2,
  };

  const captains = {
    user: userPlayers.captain,
    opponent: opponentPlayers.captain,
  };

  const drivers = userPlayers.drivers.map((driver, index) => ({
    user: driver,
    opponent: opponentPlayers.drivers[index],
  }));

  const constructors = userPlayers.constructors.map((constructor, index) => ({
    user: constructor,
    opponent: opponentPlayers.constructors[index],
  }));

  const finalFixNewPlayer = {
    user: userPlayers.finalFixNewPlayer,
    opponent: opponentPlayers.finalFixNewPlayer,
  };

  const finalFixOldPlayer = {
    user: userPlayers.finalFixOldPlayer,
    opponent: opponentPlayers.finalFixOldPlayer,
  };

  return {
    captains,
    drivers,
    constructors,
    finalFixNewPlayer,
    finalFixOldPlayer,
    userTeam: team1,
    opponentTeam: team2,
  };
}

export default useBattleMode;
