import React from "react";
import SectionLoader from "../../Loaders/SectionLoader";
import MyTeamHeader from "../../../Components/MyTeam/MyTeamHeader";
import {
  currentFixture,
  getFixtureDateForLeagues,
  getMatchStatus,
} from "../../../Common/utils/fixture";
import { PlayerBudget } from "../../PlayerBudget";
import { BoosterChips } from "../../BoosterChips";
import { useSelector } from "react-redux";
import { getPoints, getTranslations, isMobile } from "../../../Common/utils";
import { ProvisionalPtsTooltip } from "../../ProvisionalPtsTooltip";
import { ErrorMessage } from "../../Error/message";
import { EXTRA_TRANSFER_COST } from "../../../Common/constants";
import { Formations } from "../../Formations";
import { PlayerInfo } from "../../PlayerInfo";

const OppositeTeam = ({
  fetchOppDetailedTeamLoading,
  oppositeTeamDetails,
  teamNo,
  meetingFixtures,
  fixtureStart,
  fixtureEnd,
  selectedOpponentBooster,
  selectedOption,
  hasMadeExtraTransfers,
  transfersLeft,
  playerCardConfig,
}) => {
  const fixtureState = useSelector((state) => state.fixture);

  return (
    <>
      <div className="si-league__wrap">
        {fetchOppDetailedTeamLoading ? (
          <SectionLoader />
        ) : !oppositeTeamDetails ? null : (
          <>
            <div className="si-main__container">
              <div className="si-main__row">
                <div className="si-league__header si-league__header--manage">
                  <div className="si-myTeam__head">
                    <MyTeamHeader
                      teamNumber={`T${oppositeTeamDetails?.teamno}`}
                      team={{
                        name: oppositeTeamDetails?.teamname,
                        availableTransfers: oppositeTeamDetails?.usersubsleft,
                        maxTransfers: oppositeTeamDetails?.usersubs,
                      }}
                      isSelectTeam={true}
                      showCheckbox={false}
                      index={teamNo - 1}
                      showRound={true}
                      showDate={true}
                      fixRoundLbl={false}
                      roundNo={meetingFixtures?.[0]?.MeetingNumber}
                      fixDates={getFixtureDateForLeagues([
                        fixtureStart,
                        fixtureEnd,
                      ])}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="si-myTeam__grid">
              <div className="si-myTeam__grid-item">
                <div className="si-myTeam__details">
                  <PlayerBudget
                    hideBugdetProgress="true"
                    playerBudgetLabelKey=""
                    budget={oppositeTeamDetails?.budget}
                    showLimitless={oppositeTeamDetails?.boosterid === 1}
                  />
                  {selectedOpponentBooster && selectedOpponentBooster[0] ? (
                    <BoosterChips
                      boosterCls={selectedOpponentBooster?.[0]?.boosterCls}
                      boosterIcon={selectedOpponentBooster?.[0]?.boosterIcon}
                      hideBoosterText={isMobile()}
                      boosterText={selectedOpponentBooster?.[0]?.boosterText}
                    />
                  ) : (
                    ""
                  )}
                  <div className="si-myTeamHeader__transfers">
                    <span>
                      {currentFixture(fixtureState)?.GamedayId ===
                        selectedOption?.id &&
                        getMatchStatus(
                          currentFixture(fixtureState)?.MatchStatus
                        ) === "PROVISIONAL POINTS" && (
                          <ProvisionalPtsTooltip isTooltipTrue={true} />
                        )}
                      {getTranslations("my_teams_total_points", "Total Points")}
                    </span>

                    <span>
                      {getPoints(oppositeTeamDetails?.gdpoints)}{" "}
                      {getTranslations("my_team_points_short", "pts")}
                    </span>
                  </div>
                </div>
                {!!oppositeTeamDetails?.islateonboard && (
                  <div className="si-errMsg__wrap">
                    <ErrorMessage
                      text={getTranslations(
                        "completed_races_team_late_onboard_message",
                        "*Late team creation incurs a 10 point deduction. Points will be calculated from the date of creation."
                      )}
                    />
                  </div>
                )}
                {hasMadeExtraTransfers && (
                  <div className="si-errMsg__wrap">
                    <ErrorMessage
                      text={getTranslations(
                        "completed_races_team_additonal_transfer_message",
                        `Additional transfers points deducted from overall score. (-4x{{ADDITIONAL_TRANSFERS}} = -{{ADDITIONAL_TRANSFER_COST}} pts)`
                      )
                        .replace(
                          "{{ADDITIONAL_TRANSFERS}}",
                          Math.abs(transfersLeft)
                        )
                        .replace(
                          "{{ADDITIONAL_TRANSFER_COST}}",
                          Math.abs(
                            transfersLeft * oppositeTeamDetails?.extrasubscost
                          )
                        )}
                    />
                  </div>
                )}
                <div className="si-myTeam__body">
                  <div className="si-myTeam__formation">
                    <Formations
                      selectedPlayers={oppositeTeamDetails?.team}
                      playerCardConfig={playerCardConfig}
                      budget={oppositeTeamDetails?.budget}
                      turboDriver={oppositeTeamDetails?.team?.find(
                        (x) => x?.isCaptain
                      )}
                      extraTurboDriver={oppositeTeamDetails?.team?.find(
                        (x) => x?.isExtraDRS
                      )}
                      isLateOnBoard={!!oppositeTeamDetails?.islateonboard}
                      showPoints={true}
                      showReplacedPlayer={oppositeTeamDetails?.boosterid === 3}
                      replacedPlayer={oppositeTeamDetails?.team?.find(
                        (x) => x?.isFinalFixOldPlayer
                      )}
                      hideCloseButton={true}
                      isFinalFix={oppositeTeamDetails?.boosterid === 3}
                      team={oppositeTeamDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
            <PlayerInfo
              hasCTAs={false}
              isOppositeTeam={true}
              selectedGameday={selectedOption}
            />
          </>
        )}
      </div>
    </>
  );
};

export default OppositeTeam;
