import React, { Suspense, lazy, useState, useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  useRouteError,
} from "react-router-dom";
import { ALLOWED_LANGUAGES } from "../Common/constants";
import PageLoader from "../ReusableComponents/Loaders/PageLoader";
import ProtectedRoute from "./ProtectedRoute";
import EosRedirectRoute from "./EosRedirectRoute";
import routes from "./paths";
import { LeagueContextProvider, BoosterContextProvider } from "../store";
import { PageNotFound } from "../ReusableComponents";
import { getTranslations } from "../Common/utils";
import EosOfflineModeRoute from "./EosOfflineModeRoute";
import { useSelector } from "react-redux";

const App = lazy(() =>
  import(/* webpackChunkName: 'app' */ "../views/app/index")
);

const Language = lazy(() =>
  import(/* webpackChunkName: 'app' */ "../views/language")
);

const Home = lazy(() =>
  import(/* webpackChunkName: 'home' */ "../views/home/index")
);
const CreateTeam = lazy(() =>
  import(/* webpackChunkName: 'home' */ "../Components/CreateTeam")
);

const Team = lazy(() =>
  import(/* webpackChunkName: 'teams' */ "../views/teams/team")
);

const Prizes = lazy(() =>
  import(/* webpackChunkName: 'prizes' */ "../views/prizes")
);

const HowToPlay = lazy(() =>
  import(/* webpackChunkName: 'rules' */ "../views/how-to-play")
);

const GameRules = lazy(() =>
  import(/* webpackChunkName: 'rules' */ "../views/game-rules")
);

const FAQs = lazy(() => import(/* webpackChunkName: 'faqs' */ "../views/faqs"));

const Terms = lazy(() =>
  import(/* webpackChunkName: 'terms' */ "../views/terms")
);

const Achievements = lazy(() =>
  import(/* webpackChunkName: 'achievements' */ "../views/achievements")
);

const MyTeam = lazy(() =>
  import(/* webpackChunkName: 'teams' */ "../views/my-team")
);

const MyTeamDetailed = lazy(() =>
  import(/* webpackChunkName: 'teams' */ "../views/my-team/detail")
);

const OppTeamDetailed = lazy(() =>
  import(/* webpackChunkName: 'teams' */ "../views/opp-team/index")
);

const RaceWeekTeam = lazy(() =>
  import(/* webpackChunkName: 'teams' */ "../views/my-team/race-week")
);

const CompletedRacesDetail = lazy(() =>
  import(/* webpackChunkName: 'teams' */ "../views/my-team/completed-races")
);
const Leagues = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues")
);

const SelectTeam = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/select-team")
);

const JoinLeague = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/join")
);

const JoinLeagueConfirm = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/join-confirm")
);

const PrivateLeague = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/private")
);

const PublicLeague = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/public")
);

const MiniLeague = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/mini")
);
const LeagueMember = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/member")
);
const LeagueManage = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/leagues/manage")
);

const Statistics = lazy(() =>
  import(/* webpackChunkName: 'statistics' */ "../views/statistics")
);
const StatisticsDetails = lazy(() =>
  import(/* webpackChunkName: 'statistics' */ "../views/statistics/details")
);

const Webview = lazy(() =>
  import(/* webpackChunkName: 'webview' */ "../views/webview")
);

const NotFound = lazy(() =>
  import(/* webpackChunkName: 'notfound' */ "../views/not-found")
);

const Chart = lazy(() =>
  import(/* webpackChunkName: 'chart' */ "../views/chart")
);

const MiniLeagueRedirect = lazy(() =>
  import(/* webpackChunkName: 'leagues' */ "../views/mini-league-redirect")
);

const WhatsNew = lazy(() =>
  import(/* webpackChunkName: 'whatsnew' */ "../views/whats-new")
);

const childRoutes = [
  {
    element: <Home />,
    index: true,
  },
  {
    element: (
      <EosOfflineModeRoute>
        <EosRedirectRoute>
          <CreateTeam type="create" />
        </EosRedirectRoute>
      </EosOfflineModeRoute>
    ),
    path: routes.createTeam.path,
  },
  {
    element: <Prizes />,
    path: routes.prizes.path,
  },
  {
    element: <WhatsNew />,
    path: routes.whatsNew.path,
  },
  {
    element: <HowToPlay htpConfig={{}} />,
    path: routes.howToPlay.path,
  },
  {
    element: <GameRules />,
    path: routes.gameRules.path,
  },
  {
    element: <FAQs />,
    path: routes.faqs.path,
  },
  {
    element: <Terms />,
    path: routes.terms.path,
  },
  {
    element: (
      <ProtectedRoute>
        <EosOfflineModeRoute>
          <Achievements />
        </EosOfflineModeRoute>
      </ProtectedRoute>
    ),
    path: routes.achievements.path,
  },
  {
    path: routes.myTeam.path,
    children: [
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <MyTeam />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        index: true,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <EosRedirectRoute>
                <Team />
              </EosRedirectRoute>
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.manageTeam.path,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <RaceWeekTeam />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.raceWeekTeam.path,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <MyTeamDetailed />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.detailedMyTeam.path,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <CompletedRacesDetail />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.completedRacesDetailed.path,
      },
    ],
  },
  {
    path: routes.oppTeam.path,
    children: [
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <OppTeamDetailed />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.detailedOppTeam.path,
      },
    ],
  },
  {
    path: routes.leagues.path,
    children: [
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <Leagues />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        index: true,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <EosRedirectRoute>
                <SelectTeam />
              </EosRedirectRoute>
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.leagueSelectTeam.path,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <EosRedirectRoute>
                <JoinLeague />
              </EosRedirectRoute>
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.joinLeague.path,
      },
      {
        element: (
          <EosOfflineModeRoute>
            <EosRedirectRoute>
              <JoinLeagueConfirm />
            </EosRedirectRoute>
          </EosOfflineModeRoute>
        ),
        path: routes.selectedJoinLeague.path,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <LeagueMember />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.leagueMembers.path,
      },
      {
        element: (
          <ProtectedRoute>
            <EosOfflineModeRoute>
              <LeagueManage />
            </EosOfflineModeRoute>
          </ProtectedRoute>
        ),
        path: routes.leaguesManage.path,
      },
      {
        path: routes.privateLeagues.path,
        children: [
          {
            element: (
              <ProtectedRoute>
                <EosOfflineModeRoute>
                  <PrivateLeague />
                </EosOfflineModeRoute>
              </ProtectedRoute>
            ),
            index: true,
          },
        ],
      },

      {
        path: routes.publicLeagues.path,
        children: [
          {
            element: (
              <ProtectedRoute>
                <EosOfflineModeRoute>
                  <PublicLeague />
                </EosOfflineModeRoute>
              </ProtectedRoute>
            ),
            index: true,
          },
        ],
      },

      {
        path: routes.miniLeagues.path,
        children: [
          {
            element: (
              <ProtectedRoute>
                <EosOfflineModeRoute>
                  <MiniLeague />
                </EosOfflineModeRoute>
              </ProtectedRoute>
            ),
            index: true,
          },
        ],
      },
    ],
  },
  {
    path: routes.statistics.path,
    children: [
      {
        element: <Statistics />,
        index: true,
      },
      {
        element: <StatisticsDetails />,
        path: routes.statisticsDetails.path,
      },
    ],
  },
  {
    element: <Webview />,
    path: routes.webview.path,
    children: [
      {
        element: <Webview />,
        path: "*",
      },
    ],
  },
  {
    element: <Chart />,
    path: routes.performanceChart.path,
  },
  {
    element: (
      <EosOfflineModeRoute>
        <MiniLeagueRedirect />
      </EosOfflineModeRoute>
    ),
    path: routes.miniLeagueRedirect.path,
  },
  {
    element: <NotFound />,
    path: "*",
  },
];

const langRoutes = (lang) => {
  return {
    element: <Language lang={lang} />,
    path: lang,
    children: childRoutes,
  };
};

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      ...ALLOWED_LANGUAGES.map((lang) => langRoutes(lang)),
      // ...childRoutes,
      {
        element: <NotFound />,
        path: "*",
      },
    ],
  },
]);
function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;
  return (
    <PageNotFound
      pageTitle={getTranslations("error_page_title", "Something went wrong")}
      pageCaption={getTranslations(
        "error_page_caption",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
      )}
      pageThumb={`${webConfigData?.imagePaths?.commonAssets}page-not-found-thumb.png`}
      pageCta={true}
      pageCtaText={"error_page_button_text"}
      handleButtonClick={() => window.location.reload()}
    />
  );
}

function RouteConfig() {
  useEffect(() => {
    //scroll Top
    window.scroll({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  return (
    <Suspense
      fallback={
        <div className="si-master__wrap">
          <PageLoader />
        </div>
      }
    >
      <LeagueContextProvider>
        <BoosterContextProvider>
          <RouterProvider router={router} />
        </BoosterContextProvider>
      </LeagueContextProvider>
    </Suspense>
  );
}

export default RouteConfig;
