import { LEAGUE_BUSTER } from "../../../../Common/busters";
import axios from "../../axios";
import { getURLString } from "../../index";

const URLString = (url) => getURLString(BASE_ROUTE, url);
const MiniLeagueURLString = (url) => getURLString(MINI_LEAGUE_BASE_ROUTE, url);

const BASE_ROUTE = `services/user/league`;
const MINI_LEAGUE_BASE_ROUTE = `services/user/minileague`;
const GET_PUBLIC_LEAGUES = (
  guid,
  opttype,
  pageNo,
  topNo,
  filterIds,
  filtervalue
) =>
  `${guid}/${opttype}/${pageNo}/${topNo}/${filterIds}/${filtervalue}/publicleague`;
const GET_USER_STARTER_PUBLIC_LEAGUES = (guid, opttype) =>
  `${guid}/getuserpublicleague/${opttype}`;
const GET_PRIVATE_LEAGUES = (guid, opttype) =>
  `${guid}/getuserleague/${opttype}`;
const GET_MINI_LEAGUES = (guid, opttype) => `${guid}/getminileague/${opttype}`;

const CREATE_PRIVATE_LEAGUE = "createprivateleague";
const JOIN_PRIVATE_LEAGUE = "joinprivateleague";

const CREATE_H2H_LEAGUE = "createh2hleague";
const JOIN_H2H_LEAGUE = "joinh2hleague";

const CREATE_PUBLIC_LEAGUE = "createpublicleague";
const JOIN_PUBLIC_LEAGUE = "joinpublicleague";

const JOIN_MINI_LEAGUE = "joinminileague";

const LEAGUE_OWNER_ACTION = "leagueowneraction";

const DISJOIN_LEAGUE = "leaguedisjoin";

const DELETE_LEAGUE = "leaguedelete";
const DELETE_H2H_LEAGUE = "deleteh2hleague";

const SEARCH_PUBLIC_LEAGUES = (optType, leagueName) =>
  `searchuserpublicleague/${optType}/${leagueName}`;

const GET_LEAGUE_MEMBERS = (
  guid,
  optType,
  leagueid,
  isHTHLeague,
  gamedayId,
  pageNo,
  topNo,
  teamName = ""
) =>
  `${guid}/getuserleaguemembers/${optType}/${leagueid}/${isHTHLeague}/${gamedayId}/${pageNo}/${topNo}/${teamName}`;

const GET_PUBLIC_LEAGUE_MEMBERS = (
  guid,
  optType,
  leagueid,
  pageNo,
  topNo,
  userName = ""
) =>
  `${guid}/getuserspublicleaguememberssettings/${optType}/${leagueid}/${pageNo}/${topNo}/${userName}`;

const GET_USER_LEAGUE_MEMBERS_FOR_MANAGER = (
  guid,
  optType,
  leagueid,
  pageNo,
  topNo,
  userName = ""
) =>
  `${guid}/getuserleaguemembersmanager/${optType}/${leagueid}/${pageNo}/${topNo}/${userName}`;

const GET_USER_H2H_LEAGUE_MEMBERS_FOR_MANAGER = (
  guid,
  optType,
  leagueid,
  pageNo,
  topNo,
  userName = ""
) =>
  `${guid}/getuserhthleaguemembersmanager/${optType}/${leagueid}/${pageNo}/${topNo}/${userName}`;

const GET_USER_PUBLIC_LEAGUE_MEMBERS = (
  guid,
  optType,
  leagueid,
  gameDayId,
  pageNo,
  topNo,
  teamName = ""
) =>
  `${guid}/getuserpublicleaguemembers/${optType}/${leagueid}/${gameDayId}/${pageNo}/${topNo}/${teamName}`;

const GET_USER_PUBLIC_LEAGUE_MEMBERS_FOR_MANAGER = (
  guid,
  optType,
  leagueid,
  pageNo,
  topNo,
  userName = ""
) =>
  `${guid}/getuserpublicleaguemembersmanager/${optType}/${leagueid}/${pageNo}/${topNo}/${userName}`;

const GET_LEAGUE_INFO = (leagueCode) => `getleagueinfo/${leagueCode}`;

const GET_CLASSIC_PRIVATE_LEAGUES = (
  guid,
  optType,
  filterids = "0",
  filtervalues = "0"
) => `${guid}/${optType}/${filterids}/${filtervalues}/privateleague`;
// const GET_CLASSIC_PRIVATE_LEAGUES = (
//   guid,
//   optType,
//   pageNo,
//   topNo,
//   leagueName = ""
// ) => `${guid}/getuserleagueclassic/${optType}/${pageNo}/${topNo}/${leagueName}`;
// const GET_H2H_LEAGUES = (guid, optType, pageNo, topNo, leagueName = "") =>
//   `${guid}/getuserhthleague/${optType}/${pageNo}/${topNo}/${leagueName}`;
const GET_H2H_LEAGUES = (guid, opttype, filterids, filtervalues) =>
  `${guid}/${opttype}/${filterids}/${filtervalues}/hthbattle`;
const GET_H2H_SINGLE_RACE_RESULT = (guid, leagueid, teamno) =>
  `${guid}/${leagueid}/${teamno}/getsingleracehth`;
const GET_H2H_RACE_WEEK_WISE_RESULT = (guid, optType, leagueid) =>
  `${guid}/${optType}/${leagueid}/getraceweekhth`;
const GET_VIP_LEAGUES = (guid, optType, pageNo, topNo, leagueName = "") =>
  `${guid}/getuserleaguevip/${optType}/${pageNo}/${topNo}/${leagueName}`;
const GET_USER_JOINED_PUBLIC_LEAGUES = (
  guid,
  optType,
  pageNo,
  topNo,
  leagueName = ""
) =>
  `${guid}/getuserpublicleaguejoined/${optType}/${pageNo}/${topNo}/${leagueName}`;

const SEARCH_FILTER_USER_PUBLIC_LEAGUES = (
  guid,
  optType,
  filterIds,
  filterValue
) => `${guid}/searchuserpublicleague/${optType}/${filterIds}/${filterValue}`;
// const GET_ALL_MINI_LEAGUES = (guid, optType, pageNo, topNo, leagueName = "") =>
//   `${guid}/getpaginatedminileague/${optType}/${pageNo}/${topNo}/${leagueName}`;
const GET_ALL_MINI_LEAGUES = (guid, optType, filterIds, filterValues) =>
  `${guid}/${optType}/${filterIds}/${filterValues}/minileague`;

const GET_ALL_FEATURED_LEAGUES = (guid, optType, filterIds, filterValues) =>
  `${guid}/${optType}/${filterIds}/${filterValues}/featuredleague`;

const GET_ALL_PINNED_LEAGUES = (guid, optType, filterIds, filterValues) =>
  `${guid}/${optType}/${filterIds}/${filterValues}/pinnedleague`;

const UPDATE_PRIVATE_LEAGUE_NAME = "updateuserleague";
const UPDATE_H2H_LEAGUE_NAME = "updateh2hleague";
const UPDATE_PUBLIC_LEAGUE_NAME = "updatepublicleague";

const REPORT_PRIVATE_LEAGUE = "reportuserleague";
const REPORT_H2H_LEAGUE = "reporth2hleague";
const REPORT_PUBLIC_LEAGUE = "reportpublicleague";

export function getPublicLeagues(params) {
  const { guid, opttype, pageNo, topNo, filterIds, filtervalue } = params;
  return axios.get(
    URLString(
      GET_PUBLIC_LEAGUES(guid, opttype, pageNo, topNo, filterIds, filtervalue)
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getStarterPublicLeagues(params) {
  const { guid, opttype } = params;

  return axios.get(URLString(GET_USER_STARTER_PUBLIC_LEAGUES(guid, opttype)), {
    addBusterIfAvailable: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function getPrivateLeagues(params) {
  const { guid, opttype } = params;
  return axios.get(URLString(GET_PRIVATE_LEAGUES(guid, opttype)), {
    addBusterIfAvailable: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function getMiniLeagues(params) {
  const { guid, opttype } = params;
  return axios.get(MiniLeagueURLString(GET_MINI_LEAGUES(guid, opttype)), {
    addBusterIfAvailable: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function createPrivateLeague(params) {
  return axios.post(URLString(CREATE_PRIVATE_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function joinPrivateLeague(params) {
  return axios.post(URLString(JOIN_PRIVATE_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function createH2hLeague(params) {
  return axios.post(URLString(CREATE_H2H_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function joinH2hLeague(params) {
  return axios.post(URLString(JOIN_H2H_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function createPublicLeague(params) {
  return axios.post(URLString(CREATE_PUBLIC_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function joinPublicLeague(params) {
  return axios.post(URLString(JOIN_PUBLIC_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function joinMiniLeague(params) {
  return axios.post(MiniLeagueURLString(JOIN_MINI_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function leagueOwnerAction(params) {
  return axios.post(URLString(LEAGUE_OWNER_ACTION), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function disjoinLeague(params) {
  return axios.post(URLString(DISJOIN_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function deleteLeague(params) {
  return axios.post(URLString(DELETE_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function deleteH2HLeague(params) {
  return axios.post(URLString(DELETE_H2H_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function getLeagueInfo(params) {
  const { leagueCode } = params;
  return axios.get(URLString(GET_LEAGUE_INFO(leagueCode)), {
    addBusterIfAvailable: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export async function getLeagueMembers(params) {
  const {
    guid,
    optType,
    leagueid,
    isHTHLeague,
    gamedayId,
    pageNo,
    topNo,
    teamName = "",
  } = params;
  const response = await axios.get(
    URLString(
      GET_LEAGUE_MEMBERS(
        guid,
        optType,
        leagueid,
        isHTHLeague,
        gamedayId,
        pageNo,
        topNo,
        teamName
      )
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );

  if (!response.data.member) {
    return Promise.reject({ message: "No Data Found" });
  }

  return Promise.resolve(response);
}

export function getUserPublicLeagueMembers(params) {
  const {
    guid,
    optType,
    leagueid,
    gamedayId,
    pageNo,
    topNo,
    teamName = "",
  } = params;
  return axios.get(
    URLString(
      GET_USER_PUBLIC_LEAGUE_MEMBERS(
        guid,
        optType,
        leagueid,
        gamedayId,
        pageNo,
        topNo,
        teamName
      )
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}
export function getPublicLeagueMembers(params) {
  const {
    guid,
    optType,
    leagueid,
    isHTHLeague,
    gamedayId,
    pageNo,
    topNo,
    teamName = "",
  } = params;
  return axios.get(
    URLString(
      GET_PUBLIC_LEAGUE_MEMBERS(
        guid,
        optType,
        leagueid,
        isHTHLeague,
        gamedayId,
        pageNo,
        topNo,
        teamName
      )
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getLeagueMembersForManager(params) {
  const { guid, optType, leagueid, pageNo, topNo, userName = "" } = params;
  return axios.get(
    URLString(
      GET_USER_LEAGUE_MEMBERS_FOR_MANAGER(
        guid,
        optType,
        leagueid,
        pageNo,
        topNo,
        userName
      )
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getH2HLeagueMembersForManager(params) {
  const { guid, optType, leagueid, pageNo, topNo, userName = "" } = params;
  return axios.get(
    URLString(
      GET_USER_H2H_LEAGUE_MEMBERS_FOR_MANAGER(
        guid,
        optType,
        leagueid,
        pageNo,
        topNo,
        userName
      )
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getPublicLeagueMembersForManager(params) {
  const { guid, optType, leagueid, pageNo, topNo, userName = "" } = params;
  return axios.get(
    URLString(
      GET_USER_PUBLIC_LEAGUE_MEMBERS_FOR_MANAGER(
        guid,
        optType,
        leagueid,
        pageNo,
        topNo,
        userName
      )
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export async function searchPublicLeague(params) {
  const { optType, leagueName = "" } = params;
  const response = await axios.get(
    URLString(SEARCH_PUBLIC_LEAGUES(optType, leagueName))
  );

  if (!response?.data?.length) {
    return new Promise((resolve, reject) =>
      reject({ message: "No data found" })
    );
  }

  return new Promise((resolve, reject) => resolve(response));
}

export async function getClassicPrivateLeagues(params) {
  const { guid, optType, filterIds, filterValues } = params;
  const response = await axios.get(
    URLString(
      GET_CLASSIC_PRIVATE_LEAGUES(guid, optType, filterIds, filterValues)
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );

  if (!response?.data?.Details) {
    return new Promise((resolve, reject) =>
      reject({ message: "No data found" })
    );
  }

  return new Promise((resolve, reject) => resolve(response));
}

export function getVIPPrivateLeagues(params) {
  const { guid, optType, pageNo, topNo, leagueName } = params;
  return axios.get(
    URLString(GET_VIP_LEAGUES(guid, optType, pageNo, topNo, leagueName)),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getH2HPrivateLeagues(params) {
  const { guid, optType, filterIds, filterValues } = params;
  return axios.get(
    URLString(GET_H2H_LEAGUES(guid, optType, filterIds, filterValues)),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getUserJoinedPublicLeagues(params) {
  const { guid, optType, pageNo, topNo, leagueName } = params;
  return axios.get(
    URLString(
      GET_USER_JOINED_PUBLIC_LEAGUES(guid, optType, pageNo, topNo, leagueName)
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getAllMiniLeagues(params) {
  const { guid, optType, filterIds, filterValues } = params;
  return axios.get(
    URLString(GET_ALL_MINI_LEAGUES(guid, optType, filterIds, filterValues)),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export async function searchMiniLeague(params) {
  const { guid, optType, pageNo, topNo, leagueName = "" } = params;

  const response = await axios.get(
    MiniLeagueURLString(
      GET_ALL_MINI_LEAGUES(guid, optType, pageNo, topNo, leagueName)
    )
  );

  return response;
}

export function updatePrivateLeagueName(params) {
  return axios.post(URLString(UPDATE_PRIVATE_LEAGUE_NAME), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export async function updateH2HLeagueName(params) {
  const response = await axios.post(URLString(UPDATE_H2H_LEAGUE_NAME), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });

  return Promise.resolve({
    data: {
      Data: response?.data,
      body: params,
    },
  });
}

export async function updatePublicLeagueName(params) {
  const response = await axios.post(
    URLString(UPDATE_PUBLIC_LEAGUE_NAME),
    params,
    {
      bustCookie: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );

  return Promise.resolve({
    data: {
      Data: response?.data,
      body: params,
    },
  });
}

export function reportPrivateLeague(params) {
  return axios.post(URLString(REPORT_PRIVATE_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function reportH2HLeague(params) {
  return axios.post(URLString(REPORT_H2H_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function reportPublicLeague(params) {
  return axios.post(URLString(REPORT_PUBLIC_LEAGUE), params, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function getLeagueTypesWithCount(params) {
  const { url, GUID } = params;
  const urlPath = url.replace("{GUID}", GUID);

  return axios.get(URLString(urlPath), {
    addBusterIfAvailable: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}

export function getH2HSingleRaceResult(params) {
  const { guid, leagueId, teamNo } = params;
  return axios.get(
    URLString(GET_H2H_SINGLE_RACE_RESULT(guid, leagueId, teamNo)),
    params,
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getH2HRaceWeekWiseResult(params) {
  const { guid, optType, leagueId } = params;
  return axios.get(
    URLString(GET_H2H_RACE_WEEK_WISE_RESULT(guid, optType, leagueId)),
    params,
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getAllFeaturedLeagues(params) {
  const { guid, optType, filterIds, filterValues } = params;
  return axios.get(
    URLString(GET_ALL_FEATURED_LEAGUES(guid, optType, filterIds, filterValues)),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function getAllPinnedLeagues(params) {
  const { guid, optType, filterIds, filterValues } = params;
  return axios.get(
    URLString(GET_ALL_PINNED_LEAGUES(guid, optType, filterIds, filterValues)),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export function searchFilterUserPublicLeagues(params) {
  const { guid, optType, filterIds, filterValues } = params;

  return axios.get(
    URLString(
      SEARCH_FILTER_USER_PUBLIC_LEAGUES(guid, optType, filterIds, filterValues)
    ),
    {
      addBusterIfAvailable: true,
      busterCookieName: LEAGUE_BUSTER,
    }
  );
}

export async function editLeague(params) {
  const { url = "updateuserleague", body } = params;
  const response = await axios.post(URLString(url), body, {
    bustCookie: true,
    busterCookieName: LEAGUE_BUSTER,
  });

  return Promise.resolve({
    data: {
      Data: response?.data,
      body,
    },
  });
}

export function pinUnpinnedLeague(params) {
  const { url, body } = params;
  return axios.post(URLString(url), body, {
    bustCookie: true,
    addBusterIfAvailable: true,
    busterCookieName: LEAGUE_BUSTER,
  });
}
