import React from "react";

function PlayerTrends({
  trendStatus,
  costText,
  trendText,
  ariaLabel,
  showTrend = true,
  spanClass = "",
  slug,
}) {
  return (
    <>
      <div
        className={`si-player__trends ${showTrend && trendStatus}`}
        aria-label={ariaLabel ? ariaLabel : trendText}
      >
        <span className={spanClass}>
          {costText} {slug ? <em>{slug}</em> : ""}
        </span>
        {showTrend && <span>{trendText}</span>}
      </div>
    </>
  );
}

export { PlayerTrends };
