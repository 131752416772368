import React, { useState, useEffect } from "react";
import config from "../../Common/config";
import { useDispatch, useSelector } from "react-redux";
import { gameplay } from "../../redux/actions";
import { FixRound } from "../Fixtures/FixRound";
import { FixDates } from "../Fixtures/FixDates";
import MatchStatus from "../MatchStatus";
import { ErrorMessage } from "../Error/message";
import {
  currentFixture,
  getFixtureDateForLeagues,
  getGDStatus,
  getMatchStatus,
  sessionType,
} from "../../Common/utils/fixture";
import { CtaButton } from "../Buttons";
import { isUserLoggedIn } from "../../Common/utils/user";
import { useNavigate } from "react-router-dom";
import {
  getPath,
  getTranslations,
  getYearForImagePath,
} from "../../Common/utils";
import {
  CLOUDINARY_IMAGE_BASE_URL,
  IMAGE_VERSION,
} from "../../Common/constants";
import { getPreviousSeasonPerformance } from "../../redux/actions/seasonPerformance";
const MatchData = ({
  fixture,
  fixtureStart,
  fixtureEnd,
  fixtureState,
  MatchStatusWrap,
  showFixtureData = true,
  FixtureDateNewLine = false,
  ShowFixtureDate = true,
}) => {
  const getMatchStatusIcon = (status) => {
    switch (status) {
      case "LIVE":
        return "f1i-live";

      case "PC IN PROGRESS":
        return "f1i-time";

      case "PROVISIONAL POINTS":
        return "f1i-provipts";
      default:
        return "";
    }
  };

  const getMatchStatusClass = (status) => {
    switch (status) {
      case "LIVE":
        return "si-live";

      case "PROVISIONAL POINTS":
        return "si-provipts";
      default:
        return "";
    }
  };

  const getMatchStatusText = (status) => {
    switch (status) {
      case "LIVE":
        return getTranslations(
          sessionType.get(currentFixture(fixtureState)?.SessionName),
          currentFixture(fixtureState)?.SessionName
        );

      case "PC IN PROGRESS":
        return getTranslations("match_status_calculating", "Calculating...");

      case "PROVISIONAL POINTS":
        return getTranslations(
          "match_status_provisional_points",
          "Provisional Points"
        );
      default:
        return "";
    }
  };

  return (
    <div className="si-matchHeader__extra">
      {showFixtureData && (
        <div className="si-matchHeader__extra-lhs">
          <FixRound
            fixRoundConfig={{
              fixRoundLbl: false,
              roundNo: fixture?.MeetingNumber,
            }}
          />
          {ShowFixtureDate && (
            <FixDates
              fixtureStart={fixtureStart}
              fixtureEnd={fixtureEnd}
              showNewLine={FixtureDateNewLine}
            />
          )}
        </div>
      )}
      {MatchStatusWrap ? (
        <div className="si-matchHeader__extra-rhs">
          <MatchStatus
            matchStatusText={getMatchStatusText(
              getMatchStatus(currentFixture(fixtureState)?.MatchStatus)
            )}
            matchStatusIcon={getMatchStatusIcon(
              getMatchStatus(currentFixture(fixtureState)?.MatchStatus)
            )}
            matchStatusCls={getMatchStatusClass(
              getMatchStatus(currentFixture(fixtureState)?.MatchStatus)
            )}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

function MatchHeader(props) {
  // const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const fixtureState = useSelector((state) => state.fixture);
  const translationState = useSelector((state) => state.translation);
  const userState = useSelector((state) => state.user);
  const constraintsState = useSelector((state) => state.constraints);
  const previousSeasonFixture = useSelector(
    (state) => state.seasonPerformance.seasonPerformanceData
  );

  // const currentFixture = fixtureState?.list && fixtureState?.list[index];
  // const [maxFixtures, setMaxFixtures] = useState(null);
  const { fixture } = props;
  const CustomTag = props.tagName;
  let { isCurrentYear = true } = props;
  const {
    customCls,
    MatchHeaderLHS = true,
    MatchHeaderRHS = true,
    MatchHeaderExtra = false,
    MatchStatusWrap = false,
    showCTA = false,
    MatchFixtureInfo = true,
    showFixtureData = true,
    FixtureDateNewLine = false,
    ShowFixtureDate = true,
    disableCiruitClick = false,
  } = props.MatchHeaderConfig || {};

  const dispatch = useDispatch();
  const toggleCircuitPopup = () => {
    dispatch(gameplay.toggleCircuitPopup());
  };

  const webConfigState = useSelector((state) => state.webconfig);
  const { webConfigData = null } = webConfigState;

  // useEffect(() => {
  //   setMaxFixtures(fixtureState?.list?.length);
  // }, [fixtureState]);

  // function refreshFixture() {
  //   console.log(index, maxFixtures);
  //   if (index <= maxFixtures) {
  //     setIndex((prev) => prev + 1);
  //   }
  // }
  // useEffect(() => {
  //   const timerId = setInterval(refreshFixture, 1000);
  //   return function cleanup() {
  //     clearInterval(timerId);
  //   };
  // }, []);

  // let meetingFixtures = fixtureState?.list?.filter(
  //   (x) => x.MeetingId === fixture?.MeetingId
  // );

  let meetingFixtures;
  if (isCurrentYear) {
    meetingFixtures = fixtureState?.list?.filter(
      (x) => x.MeetingId === fixture?.MeetingId
    );
  } else {
    meetingFixtures = previousSeasonFixture?.Value?.fixtures?.filter(
      (x) => x.MeetingId === fixture?.MeetingId
    );
  }
  
  const fixtureStart =
    meetingFixtures?.length && meetingFixtures[0]?.SessionStartDate;
  const fixtureEnd =
    meetingFixtures?.length &&
    meetingFixtures[meetingFixtures?.length - 1]?.SessionEndDate;

  const handlePickYourTeam = () => {
    navigate(getPath(translationState, "createTeam"));
  };

  const handleViewPoints = () => {
    navigate(getPath(translationState, "myTeam"));
  };

  return (
    <>
      {(MatchHeaderLHS || MatchHeaderRHS) && (
        <div className={`si-matchHeader__box ${customCls ? customCls : ""}`}>
          {MatchHeaderLHS && (
            <section className="si-matchHeader__box-lft">
              <CustomTag className="si-matchHeader__title">
                <img
                  src={`${config.IMG_BASE}${webConfigData?.imagePaths?.flags}${fixture?.CircuitLocation}.svg?v=${IMAGE_VERSION}`}
                  alt={fixture?.CircuitLocation}
                />
                <span>{fixture?.CircuitLocation}</span>
              </CustomTag>
              {MatchFixtureInfo && (
                <div className="si-matchHeader__discrip">
                  <p>{fixture?.MeetingOfficialName}</p>
                </div>
              )}
            </section>
          )}

          {MatchHeaderRHS && (
            <section
              className="si-matchHeader__box-rgt"
              aria-label={`${fixture?.MeetingLocation} circuit`}
            >
              <button
                type="button"
                className={`si-matchHeader__circuit ${
                  disableCiruitClick ? "si-noClick" : ""
                }`}
                onClick={() => toggleCircuitPopup()}
              >
                <img
                  src={`${CLOUDINARY_IMAGE_BASE_URL}${webConfigData?.imagePaths?.circuitsTrackLogo?.replace(
                    "{seasonYear}",
                    getYearForImagePath(webConfigData)
                  )}${fixture?.MeetingLocation}.svg?v=${IMAGE_VERSION}`}
                  alt={fixture?.MeetingLocation}
                />
              </button>
            </section>
          )}
        </div>
      )}
      {MatchHeaderExtra && (
        <>
          {showCTA ? (
            <div className="si-matchHeader__extraMaster">
              <div className="si-matchHeader__extraMaster-lhs">
                <MatchData
                  {...{
                    fixture,
                    fixtureStart,
                    fixtureEnd,
                    fixtureState,
                    MatchStatusWrap,
                    showFixtureData,
                    FixtureDateNewLine,
                    ShowFixtureDate,
                  }}
                />
              </div>
              <div className="si-matchHeader__extraMaster-rhs">
                {!fixture.IsActiveForNewUser &&
                ((isUserLoggedIn(userState) && !userState?.data?.TeamCount) ||
                  !isUserLoggedIn(userState)) ? (
                  <ErrorMessage
                    text={getTranslations(
                      "team_creation_disabled_message",
                      "*Team creation temporarily unavailable during final race."
                    )}
                  />
                ) : isUserLoggedIn(userState) && userState?.data?.TeamCount ? (
                  <CtaButton
                    btnCls="si-btn si-btn__primary"
                    iconCls="f1i-chevron-right"
                    btnText={getTranslations(
                      "landing_page_raceday_lock_cta",
                      "View Teams"
                    )}
                    onClickProp={() => handleViewPoints()}
                  />
                ) : (
                  <CtaButton
                    btnCls="si-btn si-btn__primary"
                    iconCls="f1i-chevron-right"
                    btnText={getTranslations(
                      "landing_page_raceday_cta",
                      "Pick Your Team"
                    )}
                    ariaLabel={getTranslations(
                      "landing_page_raceday_cta",
                      "Pick Your Team"
                    )}
                    onClickProp={() => handlePickYourTeam()}
                  />
                )}
              </div>
            </div>
          ) : (
            <MatchData
              {...{
                fixture,
                fixtureStart,
                fixtureEnd,
                fixtureState,
                MatchStatusWrap,
                showFixtureData,
                FixtureDateNewLine,
                ShowFixtureDate,
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export { MatchHeader, MatchData };
