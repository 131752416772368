import { getMatchStatus } from "../../../Common/utils/fixture";
import {
  FETCH_STATISTICS_PENDING,
  FETCH_STATISTICS_REJECTED,
  FETCH_STATISTICS_FULFILLED,
  FETCH_RAW_STATISTICS_PENDING,
  FETCH_RAW_STATISTICS_REJECTED,
  FETCH_RAW_STATISTICS_FULFILLED,
  FETCH_DRIVER_STATISTICS_PENDING,
  FETCH_DRIVER_STATISTICS_REJECTED,
  FETCH_DRIVER_STATISTICS_FULFILLED,
  FETCH_CONSTRUCTOR_STATISTICS_PENDING,
  FETCH_CONSTRUCTOR_STATISTICS_REJECTED,
  FETCH_CONSTRUCTOR_STATISTICS_FULFILLED,
} from "../../constants/statistics";

function getPlayerData(data, players) {
  for (let index = 0; index < data.length; index++) {
    const element = data[index].participants;
    data[index].participants = element?.map((x) => ({
      ...x,
      ...players?.find((player) => player.PlayerId === x.playerid),
    }));
    data[index] = {
      ...data[index],
      ...data[index].config,
    };
  }

  return data;
}

export const statistics = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STATISTICS_PENDING:
    case FETCH_RAW_STATISTICS_PENDING:
      return { ...state, loading: true, error: false, success: false };
    case FETCH_STATISTICS_REJECTED:
    case FETCH_RAW_STATISTICS_REJECTED:
      return { ...state, loading: false, error: true, success: false };
    case FETCH_RAW_STATISTICS_FULFILLED:
      const Value = action.payload.data.Value;
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        playerRawStatistics: Value,
      };
    case FETCH_STATISTICS_FULFILLED:
      const DataValue = action?.payload?.data?.Value;

      const {
        PlayerId,
        PlayerSkill,
        GamedayWiseStats, // Weekend data for the GamedayId
        TourWiseStats, // Complete Season Data
        FixtureWiseStats, // Data for rest of the Accordion
        MatchWiseStats, // Qualifying / Race / Sprint Data
      } = DataValue;

      const RaceCompleteData = FixtureWiseStats?.map(
        ({ GamedayId, TourId, RaceDayWise }) => {
          const CountryId = RaceDayWise?.[0].CountryId;
          const CountryName = RaceDayWise?.[0].CountryName;
          const isFixtureData = RaceDayWise ? true : false;

          const WeekendTypeData = GamedayWiseStats?.find(
            (item) => item?.GamedayId === GamedayId
          );
          const isWeekendData = WeekendTypeData ? true : false;

          const MatchWiseData = MatchWiseStats?.find(
            (item) => item?.GamedayId === GamedayId
          );

          const SortedMatchSchedule = MatchWiseData?.RaceDayWise?.map(
            (item) => ({
              SessionType: item?.SessionType,
              SessionStartDate: item?.SessionStartDate,
            })
          );

          const QualifyingMatchDetails = RaceDayWise?.find(
            (item) => item?.SessionType === "Qualifying"
          );
          const QualifyingMatchId = QualifyingMatchDetails?.RaceDayId;
          const QualifyingMatchData = MatchWiseData?.RaceDayWise?.find(
            (item) => item?.RaceDayId === QualifyingMatchId
          );
          const isQualifyingMatchData = QualifyingMatchData ? true : false;

          const RaceMatchDetails = RaceDayWise?.find(
            (item) => item?.SessionType === "Race"
          );
          const RaceMatchId = RaceMatchDetails?.RaceDayId;
          const RaceMatchData = MatchWiseData?.RaceDayWise?.find(
            (item) => item?.RaceDayId === RaceMatchId
          );
          const isRaceMatchData = RaceMatchData ? true : false;

          const SprintMatchDetails = RaceDayWise?.find(
            (item) => item?.SessionType === "Sprint Qualifying"
          );
          const SprintMatchId = SprintMatchDetails?.RaceDayId;
          let SprintMatchData;
          if (SprintMatchId) {
            SprintMatchData = MatchWiseData?.RaceDayWise?.find(
              (item) => item?.RaceDayId === SprintMatchId
            );
          }
          const isSprintMatchData = SprintMatchData ? true : false;

          const isProvisionalPoints = !!RaceDayWise?.filter(
            (x) => getMatchStatus(x?.MatchStatus) === "PROVISIONAL POINTS"
          )?.length;

          return {
            GamedayId,
            TourId,
            CountryId,
            CountryName,
            isFixtureData,
            isWeekendData,
            isQualifyingMatchData,
            isRaceMatchData,
            isSprintMatchData,
            WeekendData: WeekendTypeData,
            QualifyingMatchData,
            RaceMatchData,
            SprintMatchData,
            isProvisionalPoints,
            SortedMatchSchedule,
          };
        }
      );

      const getRaceData = (raceData) => {
        let id = 2;

        return raceData?.map((item) => ({
          id: id++,
          isActive: false,
          isTabRequired: true,
          content: item,
        }));
      };

      const playerSelectedPercentage =
        FixtureWiseStats?.[FixtureWiseStats?.length - 1]?.RaceDayWise?.[
          FixtureWiseStats?.[FixtureWiseStats?.length - 1]?.RaceDayWise
            ?.length - 1
        ]?.SelectedPercentage;

      const RequiredData = [
        {
          id: 1,
          isActive: false,
          isTabRequired: false,
          content: {
            isSeasonData: true,
            isProvisionalPoints: !!RaceCompleteData?.filter(
              (x) => x.isProvisionalPoints
            )?.length,
            ...TourWiseStats?.[0],
            SelectedPercentage: playerSelectedPercentage
              ? playerSelectedPercentage
              : 0,
          },
        },
        ...getRaceData(RaceCompleteData),
      ];

      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        playerStatistics: RequiredData,
      };
    case FETCH_DRIVER_STATISTICS_PENDING:
      return {
        ...state,
        driverLoading: true,
        driverError: false,
        driverSuccess: false,
      };
    case FETCH_DRIVER_STATISTICS_REJECTED:
      return {
        ...state,
        driverLoading: false,
        driverError: true,
        driverSuccess: false,
      };
    case FETCH_DRIVER_STATISTICS_FULFILLED:
      const driverData = getPlayerData(
        action?.payload?.data?.Data?.statistics,
        action?.payload?.drivers
      );

      return {
        ...state,
        driverLoading: false,
        driverError: false,
        driverSuccess: true,
        driverStats: driverData,
        driverCurrentSeasonYear: action?.payload?.data?.Data?.season,
      };
    case FETCH_CONSTRUCTOR_STATISTICS_PENDING:
      return {
        ...state,
        constructorLoading: true,
        constructorError: false,
        constructorSuccess: false,
      };
    case FETCH_CONSTRUCTOR_STATISTICS_REJECTED:
      return {
        ...state,
        constructorLoading: false,
        constructorError: true,
        constructorSuccess: false,
      };
    case FETCH_CONSTRUCTOR_STATISTICS_FULFILLED:
      const constructorData = getPlayerData(
        action?.payload?.data?.Data?.statistics,
        action?.payload?.constructors
      );

      return {
        ...state,
        constructorLoading: false,
        constructorError: false,
        constructorSuccess: true,
        constructorStats: constructorData,
        constructorCurrentSeasonYear: action?.payload?.data?.Data?.season,
      };
    default:
      return state;
  }
};

export default statistics;
