import { combineReducers } from "redux";
import user from "./user";
import driver from "./driver";
import translation from "./translation";
import gameplay from "./gameplay";
import fixture from "./fixture";
import constraints from "./constraints";
import country from "./country";
import circuit from "./circuit";
import league from "./league";
import achievements from "./achievements";
import leaderboard from "./leaderboard";
import statistics from "./statistics";
import mixapi from "./mixapi";
import footerPartners from "./footerPartners";
import geolocation from "./geolocation";
import menu from "./menu";
import announcements from "./announcements";
import eos from "./eos";
import webconfig from "./webconfig";
import homepage from "./homepage";
import seasonPerformance from "./seasonPerformance";

export default combineReducers({
  user,
  driver,
  translation,
  gameplay,
  fixture,
  constraints,
  country,
  circuit,
  league,
  achievements,
  leaderboard,
  statistics,
  mixapi,
  footerPartners,
  geolocation,
  menu,
  announcements,
  eos,
  webconfig,
  homepage,
  seasonPerformance,
});
