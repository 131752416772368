import axios from "../../axios";
import { getURLString } from "../../index";
const URLString = (url) => getURLString(BASE_ROUTE, url);

const BASE_ROUTE = `services/session`;
const LOGIN = `login`;

export function login(params) {
  return axios.post(URLString(LOGIN), params);
}
