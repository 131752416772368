import React, { useEffect } from "react";
import { MatchHeader } from "../../MatchHeader";
import { currentFixture } from "../../../Common/utils/fixture";
import { HeadToHeadPoints } from "../../HeadToHead";
import { MatchRecord } from "../../MatchRecord";
import { useDispatch, useSelector } from "react-redux";
import { getTranslations } from "../../../Common/utils";
import { league } from "../../../redux/actions";
import {
  getH2HCurrentUserTeam,
  getH2HOpponentUserTeam,
  h2hCurrentUser,
  h2hOpponentUser,
} from "../../../Common/utils/league";

const H2HBattleModeCurrentFixture = () => {
  const dispatch = useDispatch();
  const gameplayState = useSelector((state) => state.gameplay);
  const fixtureState = useSelector((state) => state.fixture);
  const userState = useSelector((state) => state.user);
  const leagueState = useSelector((state) => state.league);

  useEffect(() => {
    try {
      const payload = {
        guid: userState?.data?.GUID,
        leagueId: leagueState.selectedLeague.leagueId,
        teamNo: 1,
      };

      dispatch(league.getH2HSingleRaceResult(payload));
    } catch (error) {}
  }, [dispatch, userState?.data?.GUID, leagueState?.selectedLeague?.leagueId]);

  return (
    <div className="si-battle__head">
      <div className="si-battle__matchHeader">
        <MatchHeader
          MatchHeaderConfig={{
            MatchHeaderRHS: false,
            MatchHeaderLHS: false,
            MatchFixtureInfo: false,
            MatchHeaderExtra: true,
          }}
          fixture={currentFixture(fixtureState)}
          tagName="h2"
        />
        <MatchHeader
          MatchHeaderConfig={{
            MatchHeaderRHS: false,
            MatchFixtureInfo: false,
          }}
          fixture={currentFixture(fixtureState)}
          tagName="h2"
        />
      </div>

      <HeadToHeadPoints
        user={h2hCurrentUser(leagueState?.h2hSingleRaceResultData)}
        opponent={h2hOpponentUser(leagueState?.h2hSingleRaceResultData)}
        userBooster={
          gameplayState?.selectedBooster?.[
            h2hCurrentUser(leagueState?.h2hSingleRaceResultData)?.teamNo - 1
          ]
        }
        opponentBooster={
          gameplayState?.h2hOppTeam?.selectedOpponentBooster?.[0]
        }
        userPoints={
          getH2HCurrentUserTeam(
            gameplayState,
            h2hCurrentUser(leagueState?.h2hSingleRaceResultData)
          )?.gdpoints ?? "-"
        }
        opponentPoints={getH2HOpponentUserTeam(gameplayState)?.gdpoints ?? "-"}
      />
      <MatchRecord
        heading={getTranslations(
          "league_hth_previous_result",
          "Previous result:"
        )}
        dataMatchRecord={h2hCurrentUser(
          leagueState?.h2hSingleRaceResultData
        )?.lastFiveResult?.map((x) => ({ type: x }))}
      />
    </div>
  );
};

export default H2HBattleModeCurrentFixture;
